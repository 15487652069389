import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ChatbotPanel from '../components/ChatbotPanel';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../Firebase';
import Banner from '../components/Banner';
import BubbleChat from '../components/BubbleChat';
import { consoleLogCustom, getAssistantSizeInPixels, getAvatarUrl, getCameraParams, getChatSize } from '../components/Utils';
import AvatarView from '../components/AvatarView';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguageFromReducedLanguage } from '../components/AvailableLanguages';
import PageTitleSection from '../components/PageTitleSection';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Dashboard(props) {

  const [myChatbots, setMyChatbots] = React.useState([]);
  const [testChatbotId, setTestChatbotId] = React.useState('');
  const [testChatbotSize, setTestChatbotSize] = React.useState('');
  const [testChatbotLanguage, setTestChatbotLanguage] = React.useState('');
  const [testChatbotBubble, setTestChatbotBubble] = React.useState('');
  const [testChatbotColor, setTestChatbotColor] = React.useState('');
  const [testChatbotType, setTestChatbotType] = React.useState('');
  const [testChatbotAid, setTestChatbotAid] = React.useState('');

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Fetch my chatbots when user logs in
  React.useEffect(() => {
    // Only fetch my chatbots if user is logged in
    if (props.user?.uid) {
      fetchMyChatbots();
    }
  }, [props.user]);

  async function fetchMyChatbots() {
    const userDocRef = doc(db, "users", props.user?.uid);
    const userDocSnap = await getDoc(userDocRef);
    let chatbots = [];
    if (userDocSnap.exists()) {
      // Read the list of chatbots from the user document
      const chatbotsList = userDocSnap.data().chatbots;
      // For chatbot in chatbots, populate our chatbots map
      for (let i = 0; i < chatbotsList.length; i++) {
        // Get the chatbot document from the chatbots collection
        const chatbotDocRef = doc(db, "chatbots", chatbotsList[i]);
        const chatbotDocSnap = await getDoc(chatbotDocRef);
        const chatbotObject = chatbotDocSnap.data();
        const chatbot = {
          id: chatbotsList[i],
          name: chatbotObject['name'],
          description: chatbotObject['description'],
          language_model: chatbotObject['language_model'],
          language: chatbotObject['language'] ?? getLanguageFromReducedLanguage(i18n.language),
          source: chatbotObject['data_source'],
          text: chatbotObject['text_source'],
          urls: chatbotObject['selected_urls'],
          domains: chatbotObject['allowed_domains'],
          type: chatbotObject['chatbot_type'],
          aid: chatbotObject['avatar_identity'],
          animation: chatbotObject['voice_animation'],
          size: chatbotObject['chatbot_size'],
          bubble: chatbotObject['chatbot_bubble'],
          color: chatbotObject['chatbot_color'],
          date: chatbotObject['creation_date'],
        }
        chatbots.push(chatbot);
      }
    } else {
      // docSnap.data() will be undefined in this case
      consoleLogCustom("No such document!");
    }
    setMyChatbots(chatbots);
  }

  function handleTryChatbot(cid, size, language, bubble, color, type, aid) {
    if (testChatbotId === cid) {
      setTestChatbotId('');
    } else {
      setTestChatbotId(cid);
      setTestChatbotSize(size);
      setTestChatbotLanguage(language);
      setTestChatbotBubble(bubble);
      setTestChatbotColor(color);
      setTestChatbotType(type);
      setTestChatbotAid(aid);
    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        {testChatbotId && (
          <div className={'fixed right-2 bottom-2 z-50'} >
            {testChatbotType === 'chat' && (
              <BubbleChat
                user={props.user}
                chatbotId={testChatbotId}
                size={getChatSize(testChatbotSize)}
                language={testChatbotLanguage}
                showBubble={testChatbotBubble}
                parent={'chattier.dev'}
                color={testChatbotColor}
              />
            )}
            {((testChatbotType === 'avatar') || (testChatbotType === 'voice')) && (
              <AvatarView
                type={testChatbotType}
                avatarUrl={getAvatarUrl(testChatbotAid)}
                showIFrame={false}
                user={props.user}
                chatbotId={testChatbotId}
                sizeType={testChatbotSize}
                size={getAssistantSizeInPixels(testChatbotType, testChatbotSize, testChatbotAid)}
                cameraParams={getCameraParams(testChatbotType, testChatbotAid)}
                language={testChatbotLanguage}
                showBubble={testChatbotBubble}
                parent={'chattier.dev'}
                aid={testChatbotAid}
              />
            )}
          </div>
        )}
        <div className='flex flex-col min-h-screen'>
          <div className='flex-1'>
            <Navbar user={props.user} logout={props.logout} />
            <PageTitleSection pageTitle={t('dashboard_title')} buttonText={t('dashboard_new_chatbot')} buttonLink='/new-chatbot' buttonColor='indigo' />
            <section className='py-8 bg-blueGray-50'>
              <div className='container px-4 mx-auto'>
                {myChatbots.map((chatbot, index) => (
                  <ChatbotPanel
                    embedChatbotInDashboardHandler={handleTryChatbot}
                    isTryingChatbot={testChatbotId === chatbot['id']}
                    user={props.user}
                    key={index}
                    chatbotId={chatbot['id']}
                    name={chatbot['name']}
                    description={chatbot['description']}
                    languageModel={chatbot['language_model'] ?? 'gpt-4o-mini-2024-07-18'}
                    language={chatbot['language'] ?? getLanguageFromReducedLanguage(i18n.language)}
                    source={chatbot['source']}
                    text={chatbot['text']}
                    urls={chatbot['urls']}
                    domains={chatbot['domains']}
                    type={chatbot['type']}
                    aid={chatbot['aid']}
                    animation={chatbot['animation']}
                    size={chatbot['size']}
                    bubble={chatbot['bubble']}
                    color={chatbot['color']}
                    date={chatbot['date']} />
                ))}
              </div>
            </section>
            {/* <Banner /> */}
          </div>
          <Footer />
        </div>
      </>
    </React.Fragment>
  );
}

