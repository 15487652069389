import React from 'react';

function Faq(props) {

  const [showAnswer, setShowAnswer] = React.useState(false);

  return (
    <div className='container max-w-max'>
      <button
        onClick={() => setShowAnswer(!showAnswer)}
        className='flex justify-between items-start px-4 py-4 bg-white max-w-max rounded-3xl border border-gray-100 shadow-xl'
      >
        <div className='text-left'>
          <h2 className='mb-5 font-heading text-lg sm:text-xl md:text-2xl leading-tighter'>
            {props.question}
          </h2>
          {showAnswer && (
            <div className='flex flex-col'>
              <p className='text-sm sm:text-md md:text-lg font-normal leading-6'>
                {props.answer}
              </p>
              {props.content}
            </div>
          )}
        </div>
        <img
          className='relative top-7 ml-10'
          src={showAnswer ? 'uinel-assets/elements/faqs/arrow-up-black.svg' : 'uinel-assets/elements/faqs/arrow-down-black.svg'}
          alt='Arrow icon for opening and closing answers'
        />
      </button>
    </div>
  )
}

export default Faq;