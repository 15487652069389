import React from 'react';
import { Link } from 'react-router-dom';

const PageTitleSection = ({ pageTitle, buttonText, buttonLink, buttonColor }) => {
  return (
    <section className='py-8 bg-blueGray-100'>
      <div className='px-4 mx-auto'>
        <div className='flex flex-row items-center justify-between -mx-4'>
          <div className='flex-none w-14 ml-4 mb-0'>
            <div className='flex flex-row items-center'>
              <img
                className='mb-0'
                src='uinel-assets/images/dashboard-navigations/icon1.svg'
                alt='Icon representing a chatbot'
              />
            </div>
          </div>
          <div className='flex-auto w-auto pl-4'>
            <div className=''>
              <div className='w-auto'>
                <h2 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-heading font-bold leading-relaxed text-left'>
                  {pageTitle}
                </h2>
              </div>
            </div>
          </div>
          <div className='flex-none w-auto pr-4'>
            <div className='flex items-center'>
              <Link
                className={'px-1 w-28 h-11 sm:w-32 sm:h-12 inline-flex items-center justify-center text-lg leading-5 text-white font-medium tracking-tighter font-heading text-center focus:ring-2 focus:ring-opacity-50 rounded-xl ' +
                  (buttonColor === 'indigo' ? ' bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500' :
                   buttonColor === 'red' ? ' bg-red-600 hover:bg-red-700 focus:ring-red-500' : '')}
                to={buttonLink}
              >
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleSection;