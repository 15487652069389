import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import chattierLogo from '../assets/uinel-assets/logos/chattier_logo_white.svg';
import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu';
import LanguageMenu from './LanguageMenu';
import BurgerMenu from './BurgerMenu';

function Navbar(props) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [burgerMenuLanguageSelectMode, setBurgerMenuLanguageSelectMode] = useState(false);
  const [userMenuPosition, setUserMenuPosition] = useState({ top: 0, left: 0 });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const userMenuRef = useRef(null);
  const languageMenuRef = useRef(null);
  const burgerMenuRef = useRef(null);
  const userMenuButtonRef = useRef(null);
  const languageMenuButtonRef = useRef(null);
  const burgerMenuButtonRef = useRef(null);

  function handleClickSignOut() {
    setUserMenuOpen(false);
    props.logout();
  }

  function handleToggleUserMenu() {
    if (props.user?.email) {
      const rect = userMenuButtonRef.current.getBoundingClientRect();
      setUserMenuPosition({ top: rect.bottom, left: rect.right - 60 });
      setUserMenuOpen(!userMenuOpen);
    } else {
      navigate('/signin');
    }
  }

  function handleClickOpenLanguageMenu() {
    setLanguageMenuOpen(!languageMenuOpen);
  }

  function handleToggleBurgerMenu() {
    setBurgerMenuOpen(!burgerMenuOpen);
    setBurgerMenuLanguageSelectMode(false);
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1280) {
        setBurgerMenuOpen(false);
        const rect = userMenuButtonRef.current.getBoundingClientRect();
        setUserMenuPosition({ top: rect.bottom, left: rect.right - 60 });
      } else {
        setUserMenuOpen(false);
        setLanguageMenuOpen(false);
      }
    }

    function handleClickOutside(event) {
      if (
        burgerMenuRef.current &&
        !burgerMenuRef.current.contains(event.target) &&
        !burgerMenuButtonRef.current.contains(event.target)
      ) {
        setBurgerMenuOpen(false); // TODO: Fix this, not working
      }
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target) &&
        !userMenuButtonRef.current.contains(event.target)
      ) {
        setUserMenuOpen(false);
      }
      if (
        languageMenuRef.current &&
        !languageMenuRef.current.contains(event.target) &&
        !languageMenuButtonRef.current.contains(event.target)
      ) {
        setLanguageMenuOpen(false);
      }
    }

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <section className='text-gray-50 font-heading font-medium relative bg-indigo-700'>
      <nav className='flex justify-between px-6 lg:px-12 py-8'>
        <div className='flex w-full items-center justify-between'>
          <div className='flex items-center justify-left'>
            <Link to='/'>
              <img className='h-12' src={chattierLogo} alt='Chattier logo' />
            </Link>
            <ul className='hidden xl:flex px-4 ml-16'>
              <li className='mr-10'>
                <Link to='/pricing' className='text-white' id='navbar-pricing-button'>
                  {t('navbar_pricing')}
                </Link>
              </li>
              <li className='mr-10'>
                <Link to='/contact' className='text-white' id='navbar-contact-button'>
                  {t('navbar_contact')}
                </Link>
              </li>
              <li className='mr-10'>
                <Link to='/about' className='text-white' id='navbar-about-button'>
                  {t('navbar_about')}
                </Link>
              </li>
              <li className='mr-10'>
                <button
                  onClick={() => {
                    window.location.href =
                      'https://chattier.getrewardful.com/signup';
                  }}
                  className='text-white' id='navbar-affiliate-button'>
                  {t('navbar_affiliate')}
                </button>
              </li>
            </ul>
          </div>
          <div className='flex items-center'>
            <button
              ref={burgerMenuButtonRef}
              id='burger-menu-button'
              className='navbar-burger self-center xl:hidden focus:outline-none'
              onClick={handleToggleBurgerMenu}>
              <svg
                width={25}
                height={16}
                viewBox='0 0 25 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <rect width={25} height={2} fill='currentColor' />
                <rect y={14} width={25} height={2} fill='currentColor' />
              </svg>
            </button>
            <div className='hidden xl:flex items-center'>
              <button
                id='user-menu-button'
                ref={userMenuButtonRef}
                onClick={handleToggleUserMenu}
                className='flex items-center text-white'>
                <span>
                  {props.user?.email
                    ? props.user?.email
                    : t('navbar_signin')}
                </span>
                {props.user?.email && (
                  <div className='ml-4 relative max-w-max'>
                    <img
                      className='w-10 h-10'
                      src='uinel-assets/elements/pricing/circle4.svg'
                      alt='User icon'
                    />
                    <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl capitalize'>
                      {props.user?.email[0]}
                    </span>
                  </div>
                )}
                {props.user?.email && (
                  <img
                    className='ml-4'
                    src='uinel-assets/elements/navigations/arrow-down-white.svg'
                    alt='Dropdown icon'
                  />
                )}
              </button>
              <div className='w-px h-8 bg-white mx-9 bg-opacity-20' />
              {props.user?.email && (
                <Link
                  to='/dashboard'
                  className='uppercase text-white text-sm font-body font-bold border-2 border-white border-opacity-30 rounded-full py-3 px-5 tracking-wide hover:border-white'>
                  <span className='block mt-px'>{t('navbar_my_chatbots')}</span>
                </Link>
              )}
              {props.user?.email && (
                <div className='w-px h-8 bg-white mx-9 bg-opacity-20' />
              )}
              <button
                ref={languageMenuButtonRef}
                type='button'
                className='w-auto justify-center bg-transparent'
                id='navbar-language-menu-button'
                aria-expanded='true'
                aria-haspopup='true'
                onClick={handleClickOpenLanguageMenu}>
                <svg
                  className='text-white w-8 h-8'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802'
                  />
                </svg>
              </button>
              <LanguageMenu
                ref={languageMenuRef}
                languageMenuOpen={languageMenuOpen}
                setLanguageMenuOpen={setLanguageMenuOpen}
              />
            </div>
          </div>
        </div>
      </nav>
      <BurgerMenu
        ref={burgerMenuRef}
        burgerMenuOpen={burgerMenuOpen}
        setBurgerMenuOpen={setBurgerMenuOpen}
        handleClickSignOut={handleClickSignOut}
        user={props.user}
        burgerMenuLanguageSelectMode={burgerMenuLanguageSelectMode}
        setBurgerMenuLanguageSelectMode={setBurgerMenuLanguageSelectMode}
      />
      <UserMenu
        ref={userMenuRef}
        userMenuOpen={userMenuOpen}
        setUserMenuOpen={setUserMenuOpen}
        handleClickSignOut={handleClickSignOut}
        top={userMenuPosition.top}
        left={userMenuPosition.left}
      />
    </section>
  );
}

export default Navbar;