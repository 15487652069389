import React from 'react';

const colorOptions = [
  { color: 'indigo', bgClass: 'bg-indigo-400' },
  { color: 'blue', bgClass: 'bg-blue-400' },
  { color: 'green', bgClass: 'bg-green-400' },
  { color: 'yellow', bgClass: 'bg-yellow-400' },
  { color: 'orange', bgClass: 'bg-orange-400' },
  { color: 'red', bgClass: 'bg-red-400' },
];

const ColorSelector = ({ selectedColor, setSelectedColor }) => {
  return (
    <div className='flex justify-center'>
      <div className='ml-0 sm:ml-9 inline-grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4'>
        {colorOptions.map((option) => (
          <button
            key={option.color}
            onClick={() => setSelectedColor(option.color)}
            className={`flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24 ${option.bgClass} rounded-2xl`}>
            {selectedColor === option.color && (
              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;