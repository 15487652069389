import React from 'react';
import { getDomainFromUrl } from './Domain';
import { CopyBlock, a11yLight } from 'react-code-blocks';
import femaleAvatar1Image from '../assets/uinel-assets/avatars/female1-image.png';
import femaleAvatar2Image from '../assets/uinel-assets/avatars/female2-image.png';
import femaleAvatar3Image from '../assets/uinel-assets/avatars/female3-image.png';
import maleAvatar1Image from '../assets/uinel-assets/avatars/male1-image.png';
import maleAvatar2Image from '../assets/uinel-assets/avatars/male2-image.png';
import maleAvatar3Image from '../assets/uinel-assets/avatars/male3-image.png';
import sphere1Image from '../assets/uinel-assets/voice_animations/sphere1.png';
import { consoleLogCustom, getAssistantSizeInPixels, getBackendUrl, stringifyDate } from './Utils';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase';
import { AvailableLanguageModels, AvailableLanguages } from './AvailableLanguages';
import { useTranslation } from 'react-i18next';

function ChatbotPanel(props) {

  const [showEmbeddedCode, setShowEmbeddedCode] = React.useState(false);
  const [chatbotDeleted, setChatbotDeleted] = React.useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function getImageUrl() {
    if (props.type === 'chat') {
      return 'uinel-assets/images/dashboard-navigations/icon1.svg'
    } else if (props.type === 'avatar') {
      switch (props.aid) {
        case 'female1':
          return femaleAvatar1Image;
        case 'female2':
          return femaleAvatar2Image;
        case 'female3':
          return femaleAvatar3Image;
        case 'male1':
          return maleAvatar1Image;
        case 'male2':
          return maleAvatar2Image;
        case 'male3':
          return maleAvatar3Image;
        default:
          return femaleAvatar1Image;
      }
    } else if (props.type === 'voice') {
      return sphere1Image;
    }
  }

  function getDataSourcesString() {
    let text = '';
    if (props.source === 'website') {
      let domains = [];
      for (let url of props.urls) {
        // Parse domain from url using regular expression
        domains.push(getDomainFromUrl(url));
      }
      // Remove duplicates from domains
      domains = [...new Set(domains)];
      if (domains.length > 1) {
        text = `${props.urls.length} ` + t('dashboard_data_sources_urls_multiple_domains');
      } else if (domains.length === 1) {
        text = `${props.urls.length} ` + t('dashboard_data_sources_urls_single_domain') + ` "${domains[0]}"`;
      } else {
        text = `${props.urls.length} ` + t('dashboard_data_sources_urls');
      }
    } else if (props.source === 'text') {
      // Get the first words of text
      text = t('dashboard_data_sources_input_text') + ': ' + props.text.substring(0, 40) + '...';
    }
    return text;
  }

  function getLastTrainedString() {
    //Get number of miliseconds from seconds and nanoseconds
    const miliseconds = props.date.seconds * 1000 + props.date.nanoseconds / 1000000;
    return stringifyDate(miliseconds, i18n.language);
  }

  function getDomainsString() {
    let domains = props.domains;
    // Remove duplicates from domains
    domains = [...new Set(domains)];
    // Return comma separated list of domains
    return domains.join(', ');
  }

  function getChatbotTypeString() {
    return t('dashboard_chatbot_type_' + props.type) +
      (
        props.type === 'chat' ?
          (' (' + (props.bubble ? t('dashboard_chatbot_type_minimizable') : t('dashboard_chatbot_type_non_minimizable')) + ', ' + t('dashboard_chatbot_type_color') + ' ' + t('color_' + props.color) + ')') : ''
      );
  }

  function handleOnClickUpdateChatbot() {
    navigate('/update-chatbot?cid=' + props.chatbotId);
  }

  function handleOnClickShowEmbedCode() {
    consoleLogCustom(showEmbeddedCode);
    setShowEmbeddedCode(!showEmbeddedCode);
  }

  async function handleOnClickDeleteChatbot() {
    consoleLogCustom('Delete chatbot');
    const decision = confirm(t('dashboard_delete_chatbot_confirmation'));
    if (!decision) {
      return;
    }
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(getBackendUrl() + "/delete-chatbot", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'uid': props.user?.uid,
        'cid': props.chatbotId,
      },
    });
    const data = await response.json();
    consoleLogCustom(data);
    setChatbotDeleted(true);
  }

  function handleOnClickTryChatbot() {
    props.embedChatbotInDashboardHandler(props.chatbotId, props.size, props.language, props.bubble, props.color, props.type, props.aid);
  }

  function getCodeForEmbedding() {
    if (props.type === 'chat') {
      return `<div>
  <iframe
    src="https://chattier.dev/chatbot-iframe?cid=${props.chatbotId}&size=${props.size}&type=${props.type}&bubble=${props.bubble}&color=${props.color}&language=${props.language}"
    width="${getAssistantSizeInPixels(props.type, props.size).width}px"
    height="${getAssistantSizeInPixels(props.type, props.size).height}px"
    frameborder="0"
    allowtransparency="true"
    referrerpolicy="no-referrer-when-downgrade"
    style="border:0; position:fixed; right: 0.5rem; bottom: 0.5rem; z-index: 500"
    allow="microphone">
  </iframe>
</div>`;
    } else if (props.type === 'avatar') {
      return `<div>
  <iframe
    src="https://chattier.dev/chatbot-iframe?cid=${props.chatbotId}&size=${props.size}&type=${props.type}&identity=${props.aid}&language=${props.language}"
    width="${getAssistantSizeInPixels(props.type, props.size, props.aid).width}px"
    height="${getAssistantSizeInPixels(props.type, props.size, props.aid).height}px"
    frameborder="0"
    allowtransparency="true"
    referrerpolicy="no-referrer-when-downgrade"
    style="border:0; position:fixed; right: 0.5rem; bottom: 0.5rem; z-index: 500"
    allow="microphone">
  </iframe>
</div>`;
    } else if (props.type === 'voice') {
      return `<div>
  <iframe
    src="https://chattier.dev/chatbot-iframe?cid=${props.chatbotId}&size=${props.size}&type=${props.type}&animation=${props.animation}&language=${props.language}"
    width="${getAssistantSizeInPixels(props.type, props.size, props.aid).width}px"
    height="${getAssistantSizeInPixels(props.type, props.size, props.aid).height}px"
    frameborder="0"
    allowtransparency="true"
    referrerpolicy="no-referrer-when-downgrade"
    style="border:0; position:fixed; right: 0.5rem; bottom: 0.5rem; z-index: 500"
    allow="microphone">
  </iframe>
</div>`;
    }
  }

  return (
    <div className={chatbotDeleted ? 'hidden' : 'relative px-8 pt-12 pb-16 mb-10 bg-blueGray-100 rounded-2xl'}>
      <button
        onClick={handleOnClickUpdateChatbot}
        className='absolute right-28 top-8 py-1 px-3 text-xxs leading-5 text-yellow-600 font-bold uppercase bg-yellow-200 rounded-2xl'>
        {t('dashboard_update_chatbot')}
      </button>
      <button
        onClick={handleOnClickDeleteChatbot}
        className='absolute right-8 top-8 py-1 px-3 text-xxs leading-5 text-red-500 font-bold uppercase bg-orange-200 rounded-2xl'>
        {t('dashboard_delete_chatbot')}
      </button>
      <div className='flex flex-wrap items-start'>
        <div className='w-full md:w-auto mb-6 md:mb-0'>
          <div className='flex items-center justify-center w-24 h-24 mr-9 bg-white rounded-5xl shadow-sm'>
            <img
              src={getImageUrl()}
              fill={props.color} //TODO
              alt='Chatbot avatar (or chat icon)'
            />
          </div>
        </div>
        <div className='w-full md:flex-1'>
          <h2 className='text-xl font-heading font-medium leading-10'>
            {props.name}
          </h2>
          <p className='mb-8 text-darkBlueGray-400 font-heading'>
            {props.description}
          </p>
          <div className='flex items-start xl:items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_chatbot_type')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {getChatbotTypeString()}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_chatbot_size')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {t('dashboard_chatbot_size_' + props.size)}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_chatbot_language_model')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {AvailableLanguageModels.find(model => model.value === props.languageModel).name}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_chatbot_language')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {t('languages_' + AvailableLanguages.find(language => language.value === props.language).value.replace('-', '_'), { rgn: '(US)' })}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_data_sources')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {getDataSourcesString()}
            </p>
          </div>
          {/* <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                Queries per day
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              268
            </p>
          </div> */}
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
              </svg>

              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_last_modified')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getLastTrainedString()}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-6 xl:mb-12'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('dashboard_installation_domains')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getDomainsString()}
            </p>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap items-end justify-end'>
        <div className='w-full sm:w-auto mb-4 sm:mb-0 sm:mr-9'>
          <button
            onClick={handleOnClickTryChatbot}
            className='block py-4 px-7 w-full text-lg leading-3 text-white font-medium tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
          >
            {props.isTryingChatbot ? t('dashboard_remove_chatbot') : t('dashboard_try_chatbot')}
          </button>
        </div>
        <div className='w-full sm:w-auto'>
          <button
            onClick={handleOnClickShowEmbedCode}
            className='block py-4 px-7 w-full text-lg leading-3 text-white font-medium tracking-tighter font-heading text-center bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-xl'
          >
            {t('dashboard_embed_chatbot')}
          </button>
        </div>
      </div>
      <div className={showEmbeddedCode ? 'ml-4 mr-4' : 'hidden ml-28 mr-28'}>
        <h2 className='text-xl font-heading font-medium leading-10'>
          {t('dashboard_embed_chatbot_title')}
        </h2>
        <p className='text-darkBlueGray-400 font-heading'>
          {t('dashboard_embed_chatbot_description')}
        </p>
        <div className='mt-2 overflow-x-hidden'>
          <CopyBlock
            // Alternatives: https://github.com/rajinwonderland/react-code-blocks#alternatives
            // TODO: Render this in a floating modal
            text={getCodeForEmbedding()}
            theme={a11yLight}
            language={'html'}
            showLineNumbers={false}
            wrapLines
            codeBlock={false}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatbotPanel;