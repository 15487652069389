import React from 'react';
import CustomTabSelector from './CustomTabSelector';
import { useTranslation } from 'react-i18next';

const ChatbotAppearanceTabSelector = ({ chatbotType, setChatbotType }) => {
  const { t } = useTranslation();
  const chatbotOptions = [
    { value: 'avatar', label: t('new_chatbot_avatar') },
    { value: 'chat', label: t('new_chatbot_chatbot') },
    { value: 'voice', label: t('new_chatbot_voice') },
  ];
  return (
    <CustomTabSelector options={chatbotOptions} selected={chatbotType} setSelected={setChatbotType} />
  );
};

export default ChatbotAppearanceTabSelector;