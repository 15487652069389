import React from 'react';
import femaleAvatar1Image from '../assets/uinel-assets/avatars/female1-image.png';
import femaleAvatar2Image from '../assets/uinel-assets/avatars/female2-image.png';
import femaleAvatar3Image from '../assets/uinel-assets/avatars/female3-image.png';
import maleAvatar1Image from '../assets/uinel-assets/avatars/male1-image.png';
import maleAvatar2Image from '../assets/uinel-assets/avatars/male2-image.png';
import maleAvatar3Image from '../assets/uinel-assets/avatars/male3-image.png';

const avatars = [
  { id: 'female1', img: femaleAvatar1Image },
  { id: 'female2', img: femaleAvatar2Image },
  { id: 'female3', img: femaleAvatar3Image },
  { id: 'male1', img: maleAvatar1Image },
  { id: 'male2', img: maleAvatar2Image },
  { id: 'male3', img: maleAvatar3Image },
];

const AvatarSelector = ({ selectedAvatar, setSelectedAvatar }) => {
  return (
    <div className='flex justify-center'>
      <div className='flex-shrink-0 ml-0 sm:ml-9 grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4'>
        {avatars.map((avatar) => (
          <button
            key={avatar.id}
            onClick={() => {
              setSelectedAvatar(avatar.id);
            }}
            className={
              (selectedAvatar === avatar.id ? 'border-4' : 'border-2') +
              ' border-solid border-indigo-600 rounded-2xl'
            }>
            <div className='flex justify-center'>
              <img
                className='w-20 h-20 sm:w-28 sm:h-28 md:w-40 md:h-40 lg:w-48 lg:h-48'
                src={avatar.img}
                alt="3D avatar image">
              </img>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AvatarSelector;