import React from 'react';
import sphere1Image from '../assets/uinel-assets/voice_animations/sphere1.png';

const animations = [
  { id: 'sphere1', img: sphere1Image },
];

const VoiceAnimationSelector = ({ selectedAnimation, setSelectedAnimation }) => {
  return (
    <div className='flex justify-center'>
      <div className='flex-shrink-0 ml-0 sm:ml-9 grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4'>
        {animations.map((animation) => (
          <button
            key={animation.id}
            onClick={() => {
              setSelectedAnimation(animation.id);
            }}
            className={
              (selectedAnimation === animation.id ? 'border-4' : 'border-2') +
              ' border-solid border-indigo-600 rounded-2xl'
            }>
            <div className='flex justify-center'>
              <img
                className='w-14 h-14 sm:w-20 sm:h-20'
                src={animation.img}
                alt="3D animation image">
              </img>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default VoiceAnimationSelector;