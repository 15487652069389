// Realistic lighting: https://www.youtube.com/watch?v=7GGNzryHfTw
// TODO: Implement animation following:
// https://github.com/Hallway-Inc/AvatarWebKit/blob/main/examples/ready-player-me-tutorials/src/AvatarView.js
// For reading face pose: https://developers.google.com/mediapipe/solutions/vision/face_landmarker/web_js?authuser=2
// For animations with blender: https://dev.to/nourdinedev/how-to-use-threejs-and-react-to-render-a-3d-model-of-your-self-4kkf

import React from 'react';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { storage } from '../Firebase';
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import AudioRecorder from './AudioRecorder';
// From https://github.com/mrdoob/three.js/blob/master/examples/textures/equirectangular/venice_sunset_1k.hdr
import backgroundUrl from '../assets/uinel-assets/avatars/venice_sunset_1k.hdr';
import { consoleLogCustom, createNewConversation, getBackendUrl } from './Utils';
import { AvailableLanguages } from './AvailableLanguages';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { getDomainFromUrl } from './Domain';
import { ChatHistory } from './ChatHistory';
import noise from './Perlin';

class AvatarView extends React.Component {

  mainViewRef = React.createRef()
  chatHistory = new ChatHistory()
  chatHistoryTokens = null
  conversationId = null
  referenceTime = 0
  speechAnimationRunning = false
  blinkAnimationRunning = false
  blinkValue = 0
  blinkAscending = true
  audioRecorder = new AudioRecorder(-45) // -45 is the default value for voiceMinDecibels
  lastAudioTimeRef = null
  maxAudioTimeRef = 29 // Maximum recording time in seconds (client.recognize should give error for audios longer than 60 seconds, but in fact the error appears on 30 seconds)
  settingsMenuOpen = false
  language = this.getDefaultLanguage()
  backendHost = new URL(getBackendUrl()).host
  ws = null
  audioContext = null
  audioInput
  audioWorkletNode
  playbackQueue = []
  activeTrajectories = null
  isPlaying = false
  transcript = ''
  audioPlayTime = null
  analyserNodeInput
  analyserNodeOutput
  dataArrayInput
  dataArrayOutput

  // If we want to use variables in JSX, they need to be inside state
  state = {
    avatarState: 'standby',
    audioHist: [0, 0, 0],
  }

  setAvatarState = (value) => {
    this.setState({
      avatarState: value,
      audioHist: this.state.audioHist,
    });
  }

  setAudioHist = (value) => {
    this.setState({
      avatarState: this.state.avatarState,
      audioHist: value,
    });
  }

  constructor(props, context) {
    super(props, context)
    this.startQuery = this.startQuery.bind(this)
    this.volume = 0;
    this.volumeFiltered = 0;
    this.analyseAudio = this.analyseAudio.bind(this);
    this.analyseAudio();
  }

  getAudioVolume(analyserNode, dataArray) {
    analyserNode.getByteFrequencyData(dataArray);
    const sum = dataArray.reduce((acc, val) => acc + (val * val), 0);
    return Math.sqrt(sum / dataArray.length);
  }

  analyseAudio() {
    if (this.state.avatarState == 'recording' && this.analyserNodeInput && this.dataArrayInput) {
      this.volume = this.getAudioVolume(this.analyserNodeInput, this.dataArrayInput);
    } else if (this.state.avatarState == 'speaking' && this.analyserNodeOutput && this.dataArrayOutput) {
      this.volume = this.getAudioVolume(this.analyserNodeOutput, this.dataArrayOutput);
    } else {
      this.volume = 0;
    }
    // Filter the volume to avoid sudden changes
    this.volumeFiltered = 0.9 * this.volumeFiltered + 0.1 * this.volume; // Low-pass filter
    // Schedule next analysis
    requestAnimationFrame(this.analyseAudio);
  }

  async handleAudioVisemesData(arrayBuffer) {
    // Check if audioContext is available
    if (!this.audioContext) {
      console.error('AudioContext is not initialized.');
      return;
    }

    try {
      // Process incoming data
      const { audioBuffer, visemesData } = await this.decodeAudioVisemesData(arrayBuffer);

      // Validate audioBuffer before proceeding
      if (!audioBuffer) {
        console.error('Invalid audio data received');
        return;
      }

      let trajectory = null;
      let duration = audioBuffer.duration;

      if (visemesData) {
        // Check for timing consistency
        if (Math.abs(audioBuffer.duration - visemesData.duration) > 0.1) {
          console.warn('Audio/visemes duration mismatch:', {
            audio: audioBuffer.duration,
            visemes: visemesData.duration,
          });
        }

        // Process visemes and generate trajectory
        trajectory = await this.generateBlendshapeTrajectories(
          visemesData.data,
          visemesData.duration
        );
        duration = visemesData.duration;
      }

      // Enqueue for playback
      this.playbackQueue.push({
        audioBuffer: audioBuffer,
        trajectoryData: trajectory ? { trajectory, duration } : null,
        receivedTime: this.audioContext.currentTime,
      });

      if (!this.isPlaying) {
        this.playAudioBufferQueue(visemesData !== null);
      }
    } catch (error) {
      console.error('Error processing audio/visemes data:', error);
    }
  }

  async decodeAudioVisemesData(arrayBuffer) {
    const dataView = new DataView(arrayBuffer);
    const visemesLength = dataView.getUint32(0);

    let visemesData = null;
    let audioData;

    if (visemesLength === 0) {
      // No visemes data, only audio (for voice chatbot)
      audioData = arrayBuffer.slice(4);
    } else {
      // Extract and parse visemes (for avatar chatbot)
      const visemesBytes = arrayBuffer.slice(4, 4 + visemesLength);
      const visemesJson = new TextDecoder('utf-8').decode(visemesBytes);
      visemesData = JSON.parse(visemesJson);

      // Extract audio data after visemes
      audioData = arrayBuffer.slice(4 + visemesLength);
    }

    // Decode audio data
    const audioBuffer = await this.audioContext.decodeAudioData(audioData);

    return { audioBuffer, visemesData };
  }

  async playAudioBufferQueue(includesVisemes) {
    this.isPlaying = true;
    let lastEndTime = this.audioContext.currentTime;

    while (this.playbackQueue.length > 0) {
      const item = this.playbackQueue.shift();
      const buffer = item.audioBuffer;
      const trajectoryData = item.trajectoryData;

      // Ensure minimum gap between segments
      const minGap = 0.05; // 50ms
      lastEndTime = Math.max(lastEndTime + minGap, this.audioContext.currentTime);

      // Schedule next audio
      const startTime = this.playAudioBuffer(buffer);
      lastEndTime = startTime + buffer.duration;

      // Synchronize animation precisely
      if (includesVisemes && trajectoryData) {
        this.startAvatarAnimation(
          trajectoryData.trajectory,
          trajectoryData.duration,
          startTime
        );
      }

      // Wait for completion with timing check
      await new Promise((resolve) => {
        const waitTime = (buffer.duration * 1000) + 50; // Add 50ms buffer
        setTimeout(resolve, waitTime);
      });
    }

    // Reset avatar state if not already set by animation
    this.isPlaying = false;
    if (!this.speechAnimationRunning) {
      this.setAvatarState('standby');
    }
  }

  playAudioBuffer(buffer) {
    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;

    // Create AnalyserNode
    this.analyserNodeOutput = this.audioContext.createAnalyser();
    this.analyserNodeOutput.fftSize = 2048; // Set the FFT size (must be a power of 2)

    // Create a buffer to hold the frequency data
    this.dataArrayOutput = new Uint8Array(this.analyserNodeOutput.frequencyBinCount);

    source.connect(this.analyserNodeOutput);
    this.analyserNodeOutput.connect(this.audioContext.destination);

    // // Ensure that audioPlayTime is not in the past
    // if (this.audioPlayTime < this.audioContext.currentTime) {
    //   this.audioPlayTime = this.audioContext.currentTime;
    // }

    // Add small scheduling buffer
    const schedulingBuffer = 0.1; // 100ms
    const nextPlayTime = Math.max(
      this.audioContext.currentTime + schedulingBuffer,
      this.audioPlayTime
    );
    this.audioPlayTime = nextPlayTime;

    // Update avatar state to 'speaking'
    this.setAvatarState('speaking');

    source.start(this.audioPlayTime);

    // Capture the exact start time
    const startTime = this.audioPlayTime;

    // Update audioPlayTime for the next audio buffer
    this.audioPlayTime += buffer.duration;

    // Return the start time
    return startTime;
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      return {
        value: props.value,
        computed_prop: heavy_computation(props.value)
      }
    }
    return null
  }

  async stopRecording() {

    this.audioWorkletNode.disconnect();
    this.audioInput.disconnect();
    this.stream.getTracks().forEach((track) => {
      track.stop();
    });
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ type: 'end' }));
    }

    if (this.state.avatarState == 'recording') {
      this.setAvatarState('processing');
    }

    consoleLogCustom('Recording stopped');
  }

  async startQuery() {
    // Initialize audio context
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.audioPlayTime = this.audioContext.currentTime;
    consoleLogCustom('Created audio context');

    // Initialize WebSocket connection
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      this.ws = new WebSocket(`${wsProtocol}//${this.backendHost}/ws/audio-stream/`);
      this.ws.binaryType = 'arraybuffer';

      this.ws.onmessage = async (event) => {
        if (typeof event.data === 'string') {
          // Handle text messages
          const message = JSON.parse(event.data);
          if (message.type === 'transcript') {
            this.transcript += message.data + ' ';
            consoleLogCustom('Accumulated Transcript:', this.transcript);
          } else if (message.type === 'text') {
            this.chatHistory.addQueryAndResponse(message);
          }
        } else if (event.data instanceof ArrayBuffer) {
          // Handle binary messages (audio and visemes)
          await this.handleAudioVisemesData(event.data);
        }
      };

      this.ws.onopen = () => {
        // Start recording or send initial data if necessary
        consoleLogCustom('WebSocket connection opened.');
      };

      this.ws.onclose = async () => {
        consoleLogCustom('WebSocket connection closed.');
        await this.stopRecording();
        this.ws = null;
      };

      this.ws.onerror = (error) => {
        consoleLogCustom('WebSocket error:', error);
        // Handle error, maybe notify the user or attempt reconnection
      };

      // Wait for WebSocket connection to open
      while (this.ws.readyState !== WebSocket.OPEN) {
        await new Promise(r => setTimeout(r, 500)); // 500 ms
        consoleLogCustom('Waiting for WebSocket connection to open');
      }
    }

    // If conversationId is null, we need to start a new conversation
    try {
      if (this.conversationId == null) {
        consoleLogCustom('Starting new conversation');
        this.conversationId = await createNewConversation(
          this.props?.chatbotId,
        )
        consoleLogCustom('Conversation started with id:', this.conversationId);
      }

      /* Start streaming audio through websocket connection to backend */

      // Load audio worklet processor
      await this.audioContext.audioWorklet.addModule('js/audioWorklet/processor.js');

      // Request permission to use the microphone and get the audio stream
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      consoleLogCustom('Got audio stream:', this.stream);

      // Create audio worklet node
      this.audioInput = this.audioContext.createMediaStreamSource(this.stream);
      this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'processor');
      consoleLogCustom('Created audio worklet node');

      // Create AnalyserNode
      this.analyserNodeInput = this.audioContext.createAnalyser();
      this.analyserNodeInput.fftSize = 2048; // Set the FFT size (must be a power of 2)

      // Create a buffer to hold the frequency data
      this.dataArrayInput = new Uint8Array(this.analyserNodeInput.frequencyBinCount);

      // Connect the audio input to the input analyser node
      this.audioInput.connect(this.analyserNodeInput);

      // Send initial configuration message to backend before starting the audio stream
      this.ws.send(
        JSON.stringify(
          {
            type: 'config',
            data: {
              cid: this.props?.chatbotId,
              parent: this.props?.parent,
              aid: this.props?.aid,
              language: this.language,
              conversationId: this.conversationId,
              chatHistoryRaw: this.chatHistory.getHistory(),
              shopify: null,
            }
          }
        )
      );

      // Send audio data to backend
      this.audioWorkletNode.port.onmessage = (event) => {
        const arrayBuffer = event.data;
        // consoleLogCustom('Sending audio data of size:', arrayBuffer.byteLength);
        this.ws.send(arrayBuffer);
      };
      consoleLogCustom('Set audio worklet node port on message');

      // Connect audio input to audio worklet node
      this.audioInput.connect(this.audioWorkletNode);
      consoleLogCustom('Connected audio input to audio worklet node');

      // Start audio worklet node if suspended
      consoleLogCustom('Audio context state:', this.audioContext.state);
      if (this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
        consoleLogCustom('Resumed audio context');
      }

      // Reset the play time to the current time
      this.audioPlayTime = this.audioContext.currentTime;
      consoleLogCustom('Reset audio play time');

      if (this.state.avatarState != 'recording') {
        this.setAvatarState('recording');
        // Continue when avatarState setting has been updated
        while (this.state.avatarState != 'recording') {
          await new Promise(r => setTimeout(r, 50)); // 50 ms
          consoleLogCustom('Waiting for avatarState to be recording');
        }
      }
      consoleLogCustom('Avatar state is recording');

      // Wait until avatarState has updated
      while (this.state.avatarState == 'recording') {
        await new Promise(r => setTimeout(r, 50)); // 50 ms
        consoleLogCustom('Waiting for recording to stop');
      }
      consoleLogCustom('Recording stopped');

    } catch (error) {
      console.error('Error in startQuery:', error);
      throw error;
    }
  }

  async generateBlendshapeTrajectories(visemesInfo, duration) {
    // Generate blendshape trajectories from visemes and timestamps
    // TODO: Always end with 'sil' viseme to ensure the avatar returns to the neutral position
    const nframes = Math.floor(duration * 60);
    const visemeEquivalences = {
      // https://docs.readyplayer.me/ready-player-me/api-reference/avatars/morph-targets/oculus-ovr-libsync
      // https://docs.aws.amazon.com/polly/latest/dg/ph-table-english-uk.html (see all languages)
      'viseme_sil': ['sil'],
      'viseme_PP': ['p', 'B', 'b', '0'],
      'viseme_FF': ['f'],
      'viseme_TH': ['T'],
      'viseme_DD': ['t', 'd', 'D'],
      'viseme_kk': ['k'],
      'viseme_CH': ['S', 'J'],
      'viseme_SS': ['s'],
      'viseme_nn': [],
      'viseme_RR': ['r'],
      'viseme_aa': ['@', 'a'],
      'viseme_E': ['e', 'E'],
      'viseme_I': ['i'],
      'viseme_O': ['o', 'O'],
      'viseme_U': ['u'],
    }
    const nvisemes = Object.keys(visemeEquivalences).length;

    // Extract timestamps and visemes
    const timestamps = visemesInfo.map((viseme) => viseme['time']);
    const visemes = visemesInfo.map((viseme) => viseme['value']);

    // Convert visemes using visemeEquivalences
    let convertedVisemes = [];
    let visemeKeys = Object.keys(visemeEquivalences);
    let visemeValues = Object.values(visemeEquivalences);

    visemes.forEach((viseme) => {
      visemeValues.forEach((visemeValue) => {
        if (visemeValue.includes(viseme)) {
          convertedVisemes.push(visemeKeys[visemeValues.indexOf(visemeValue)]);
        }
      });
    });

    // Initialize trajectories as a double array of size nvisemes x nframes
    let trajectories = {};
    for (let i = 0; i < nvisemes; i++) {
      trajectories[visemeKeys[i]] = new Array(nframes).fill(0);
    }

    // For each timestamp, generate the corresponding viseme trajectory
    const scaleFactor = 0.4;
    const slopeTime = 100; // ms
    timestamps.forEach((timestamp, index) => {
      // Get the value of the next timestamp. If there is no next timestamp, use the same value as the current timestamp
      let nextTimestamp = timestamps[index + 1] ? timestamps[index + 1] : timestamp;
      // Also, if the next index is the last one ('sil'), then use the same value as the current timestamp
      // This is because the last viseme is always 'sil', and the viseme before 'sil' does not need to be maintained
      let descentSlopeScaleFactor = 1; // Only used for smoothing the last descent slope
      if (index + 1 === timestamps.length - 1) {
        nextTimestamp = timestamp;
        descentSlopeScaleFactor = 2;
      }
      // Transform timestamps to frames
      const timeframe = Math.floor(timestamp * 60 / 1000);
      const nextTimeframe = Math.floor(nextTimestamp * 60 / 1000);
      // Calculate the number of frames for the linear slope
      let slopeFrames = Math.floor(slopeTime * 60 / 1000);
      // Limit slopeFrames in case the next timeframe is too close to the end of the audio
      if (nframes - nextTimeframe < slopeFrames) {
        slopeFrames = nframes - nextTimeframe;
      }
      // Limit slopeFrames in case the current timeframe is too close to the beginning of the audio
      if (timeframe < slopeFrames) {
        slopeFrames = timeframe;
      }
      // Set a linear slope starting slopeTime ms before the current timeframe for this viseme
      for (let i = timeframe - slopeFrames; i < timeframe; i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor * (i - timeframe + slopeFrames) / slopeFrames;
      }
      // Set to 1 the values of the trajectory between the current and next timeframe for this viseme
      for (let i = timeframe; i < nextTimeframe; i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor;
      }
      // Set a linear slope ending slopeTime ms after the next timeframe for this viseme
      for (let i = nextTimeframe; i < nextTimeframe + (slopeFrames * descentSlopeScaleFactor); i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor * (nextTimeframe + (slopeFrames * descentSlopeScaleFactor) - i) / (slopeFrames * descentSlopeScaleFactor);
      }
    });

    return trajectories;
  }

  loadBackground(url, renderer) {
    return new Promise((resolve) => {
      const loader = new RGBELoader()
      const generator = new THREE.PMREMGenerator(renderer)
      loader.load(url, (texture) => {
        const envMap = generator.fromEquirectangular(texture).texture
        generator.dispose()
        texture.dispose()
        resolve(envMap)
      })
    })
  }

  componentWillUnmount() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
      this.audioPlayTime = null;
    }
    // Additional cleanup if necessary
  }

  async componentDidMount() {
    const mainView = this.mainViewRef.current
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setSize(this.props.size.width, this.props.size.height)
    this.renderer.outputColorSpace = THREE.SRGBColorSpace
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = 3;
    this.renderer.shadowMap.enabled = true;
    this.renderer.setClearColor(0x000000, 0); // the default
    mainView.appendChild(this.renderer.domElement)

    this.camera = new THREE.PerspectiveCamera(50, 1, 0.1, 2000)
    this.camera.position.set(0, 0.5, 4)
    // this.camera.rotation.set(-0.27, 0, 0)
    this.camera.lookAt(0, 0, -25)

    // this.controls = new OrbitControls(this.camera, this.renderer.domElement) // If control allowed, prevents lookat function from working

    this.scene = new THREE.Scene()
    // this.scene.environment = new THREE.Color(0xffffff)

    // Using lighting from the background instead of creating our own
    const background = await this.loadBackground(backgroundUrl, this.renderer)

    this.scene.environment = background
    this.scene.background = null // We do not need to render the background, only for lighting

    // // Make background transparent
    // this.scene.background = null

    // let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2)
    // this.scene.add(hemiLight)

    // this.spotLight = new THREE.SpotLight(0xffffff, 3)
    // this.spotLight.castShadow = true;
    // this.spotLight.shadow.bias = -0.0001;
    // this.spotLight.shadow.mapSize.width = 1024 * 4;
    // this.spotLight.shadow.mapSize.height = 1024 * 4;
    // this.scene.add(this.spotLight);


    // this.scene.add(new THREE.AxesHelper(500)) // BORRAR

    await this.loadModel()

    // Log the morphTargetDictionary of each child, indicating the name of the child
    // this.avatar.children.forEach((child) => consoleLogCustom(child.name, child.morphTargetDictionary))

    // Create render loop
    this.renderer.setAnimationLoop(this.renderScene.bind(this))
  }

  async startAvatarAnimation(trajectories, duration, startTime) {
    // For the duration of the audio, animate the avatar by calling animateSpeechAnimationFrame

    // Set the active trajectory
    this.activeTrajectories = trajectories;

    // Calculate the delay before starting the animation
    const currentTime = this.audioContext.currentTime;
    const delay = (startTime - currentTime) * 1000; // in ms

    // Wait for the delay to synchronize with audio playback
    if (delay > 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
    }

    // Get the reference time for the animation
    this.referenceTime = performance.now();

    // Set the speechAnimationRunning flag to true
    this.speechAnimationRunning = true;

    // Schedule the end of the animation
    setTimeout(() => {
      this.speechAnimationRunning = false;
      // Update avatarState
      if (this.state.avatarState == 'speaking' && !this.isPlaying) {
        this.setAvatarState('standby');
        this.audioContext.close();
        this.audioContext = null;
        this.audioPlayTime = null;
      }
    }, duration * 1000);

    // // For each viseme, animate the avatar
    // visemes.forEach((viseme) => {
    //   setTimeout(() => {
    //     this.animateAvatar(viseme);
    //   }
    //     , viseme['time'] * 1000);
    // }
    // );

  }

  animateSpeechAnimationFrame() {

    // const hips = this.avatar.children.find((child) => child.name === 'Hips');
    // const eyeLeft = this.avatar.children.find((child) => child.name === 'EyeLeft');
    // const eyeRight = this.avatar.children.find((child) => child.name === 'EyeRight');
    // const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Head');
    // const teeth = this.avatar.children.find((child) => child.name === 'Wolf3D_Teeth');
    // const body = this.avatar.children.find((child) => child.name === 'Wolf3D_Body');
    // const outfilBottom = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Bottom');
    // const outfitFootwear = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Footwear');
    // const outfitTop = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Top');
    // const hair = this.avatar.children.find((child) => child.name === 'Wolf3D_Hair');

    const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Avatar');

    // // Print morph targets
    // if (Math.floor(this.renderer.info.render.frame % 60) === 0) {
    //   consoleLogCustom('head.morphTargetDictionary: ', head.morphTargetDictionary);
    // }

    // Get the current frame index
    let frameIndex = Math.floor((performance.now() - this.referenceTime) * 60 / 1000); // 60 fps

    // Limit frame index to the length of the active trajectories
    const nframes = Object.values(this.activeTrajectories)[0].length;
    if (frameIndex >= nframes) {
      frameIndex = nframes - 1;
    }

    // Update visemes using trajectories
    const arKitKeys = Object.keys(this.activeTrajectories);
    for (let i = 0; i < arKitKeys.length; i++) {
      const arKitKey = arKitKeys[i];
      const index = head.morphTargetDictionary[arKitKey];
      if (index !== undefined) {
        head.morphTargetInfluences[index] = this.activeTrajectories[arKitKey][frameIndex];
      }
    }

    let { pitch, roll, yaw } = this.avatar.rotation
    let { x, y, z } = this.avatar.position

    roll -= 0.001
    y += 0.001

    // this.avatar.rotation.set(pitch, roll, yaw);
    // this.avatar.position.set(x, y, z);

    // Every second
    if (Math.floor(this.renderer.info.render.frame % 60) === 0) {
      // consoleLogCustom('head.morphTargetInfluences[index]: ', head.morphTargetInfluences[index]);
    }
  }

  animateBlinkAnimationFrame() {
    const eyeLeft = this.avatar.children.find((child) => child.name === 'EyeLeft');
    const eyeRight = this.avatar.children.find((child) => child.name === 'EyeRight');
    const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Avatar');

    const blinkFrames = 6;

    const arKitKeys = ['eyeBlinkLeft', 'eyeBlinkRight'];

    if (this.blinkValue === 0) {
      this.blinkAscending = true;
    } else if (this.blinkValue === blinkFrames) {
      this.blinkAscending = false;
    }

    if (this.blinkAscending) {
      this.blinkValue += 1;
    } else {
      this.blinkValue -= 1;
    }

    for (let i = 0; i < arKitKeys.length; i++) {
      const arKitKey = arKitKeys[i];
      const index = head.morphTargetDictionary[arKitKey];
      head.morphTargetInfluences[index] = this.blinkValue * (1 / blinkFrames);
    }

    if ((this.blinkValue === 0) && (this.blinkAscending === false)) {
      this.blinkAnimationRunning = false;
    }
  }

  animateVoiceAnimationFrame() {
    // Get the geometry from the Mesh
    const geometry = this.avatar.geometry;
    const material = this.avatar.material;
    const time = Date.now() * 0.001;
    const vertices = geometry.attributes.position.array;
    const originalVertices = geometry.userData.originalVertices ||
      Array.from(vertices);

    if (this.state.avatarState == 'recording') {
      // material.emissiveIntensity = 0.2 + this.volumeFiltered / 200;
      // material.color.setHSL(time * 0.1, 0.8, 0.5); // Shift hue over time
    }

    // Store original vertices
    if (!geometry.userData.originalVertices) {
      geometry.userData.originalVertices = originalVertices;
    }

    // Apply noise-based displacement
    for (let i = 0; i < vertices.length; i += 3) {
      const vertex = new THREE.Vector3(
        originalVertices[i],
        originalVertices[i + 1],
        originalVertices[i + 2]
      );

      // Add multiple layers of noise
      const noiseScale = 2;
      const displacement = (0.7 + this.volumeFiltered / 41) *
        noise.perlin3(
          vertex.x * noiseScale + time * 0.5,
          vertex.y * noiseScale,
          vertex.z * noiseScale
        ) * 0.15 +
        noise.perlin3(
          vertex.x * noiseScale * 2 + time * 0.7,
          vertex.y * noiseScale * 2,
          vertex.z * noiseScale * 2
        ) * 0.05;

      vertex.normalize().multiplyScalar(1 + displacement);

      vertices[i] = vertex.x;
      vertices[i + 1] = vertex.y;
      vertices[i + 2] = vertex.z;
    }

    geometry.attributes.position.needsUpdate = true;
    geometry.computeVertexNormals(); // Update normals for smooth shading

    // Subtle rotation
    this.avatar.rotation.x += 0.001;
    this.avatar.rotation.y += 0.002;

    // Every second
    if (Math.floor(this.renderer.info.render.frame % 60) === 0) {
      // consoleLogCustom('head.morphTargetInfluences[index]: ', head.morphTargetInfluences[index]);
    }
  }

  async componentDidUpdate(oldProps) {

    if ((this.props?.avatarUrl && (this.props?.avatarUrl !== oldProps?.avatarUrl))
      || (this.props?.size.width && (this.props?.size.width !== oldProps?.size.width))
      || (this.props?.size.height && (this.props?.size.height !== oldProps?.size.height))) {
      await this.loadModel()
    }

    // this.renderer.domElement.style.cssText = `display: ${this.props.showIFrame ? 'block' : 'none'}`
  }

  loadBackground(url, renderer) {
    return new Promise((resolve) => {
      const loader = new RGBELoader()
      const generator = new THREE.PMREMGenerator(renderer)
      loader.load(url, (texture) => {
        const envMap = generator.fromEquirectangular(texture).texture
        generator.dispose()
        texture.dispose()
        resolve(envMap)
      })
    })
  }

  async loadModel() {
    // Set the avatar state to processing so that the loading spinner is shown while the avatar is loading
    this.setAvatarState('processing')
    // If there are any other avatars in the scene, remove them
    if (this.avatar != undefined) {
      this.scene.remove(this.avatar);
    }
    // Change the window size
    if (this.props.size) {
      this.renderer.setSize(this.props.size.width, this.props.size.height)
      this.camera.aspect = this.props.size.width / this.props.size.height
      this.camera.updateProjectionMatrix()
    }
    // Load avatar
    if (this.props.type === 'avatar') {
      const gltf = await this.loadGLTF(this.props.avatarUrl)
      this.avatar = gltf.scene.children[0]
    } else if (this.props.type === 'voice') {
      const geometry = new THREE.SphereGeometry(1, 128, 128);
      geometry.computeVertexNormals(); // Smooth normals
      const material = new THREE.MeshPhysicalMaterial({
        color: 0x9370DB,         // Medium purple
        metalness: 0.9,
        roughness: 0.1,
        envMapIntensity: 1.2,    // Slightly increased for purple reflection
        clearcoat: 0.6,          // Increased for more shine
        clearcoatRoughness: 0.2,
        sheenColor: 0xb19cd9,    // Light purple sheen
        sheenRoughness: 0.2
      });
      this.avatar = new THREE.Mesh(geometry, material);
    }
    this.avatar.position.set(this.props.cameraParams.position.x, this.props.cameraParams.position.y, this.props.cameraParams.position.z)
    this.avatar.scale.setScalar(this.props.cameraParams.scale)
    this.avatar.traverse(n => {
      if (n.isMesh) {
        n.castShadow = true
        n.receiveShadow = true
        if (n.material.map) n.material.map.anisotropy = 16
      }
    })
    this.scene.add(this.avatar)
    // Return the avatar state to standby
    this.setAvatarState('standby')
  }

  renderScene() {
    // Get audio frequency data
    if (this.state.avatarState == 'recording') {
      try {
        // If the audio volume is below the minimum threshold, begin countdown to stop recording
        if (this.volume > 19.76) {
          this.lastAudioTimeRef = this.audioContext.currentTime;
        }
        let audioHist = [0, 0, 0];
        const adjustedVolume = this.volume / 255 * 25;
        // Limit between 0 and 25
        if (adjustedVolume < 0) {
          audioHist[1] = 0;
        } else if (adjustedVolume > 25) {
          audioHist[1] = 25;
        } else {
          audioHist[1] = adjustedVolume;
        }
        audioHist[0] = audioHist[1] / 2;
        audioHist[2] = audioHist[1] / 1.5;
        this.setAudioHist(audioHist);
        //TODO: Use an offline speech recognition library to detect no speech instead (only for this purpose, not for the actual speech recognition)
        //TODO: Or alternatively, use a stream of audio data instead of a file
        if ((this.audioContext.currentTime - this.lastAudioTimeRef > 3) || // If there has been no audio for 3 seconds
          (this.audioContext.currentTime > this.maxAudioTimeRef)) { // Or if the maximum recording time has been reached
          this.stopRecording();
        }
      } catch (error) {
        consoleLogCustom(error);
      }
    }
    // // Update spotLight position
    // this.spotLight.position.set(
    //   this.camera.position.x + 10,
    //   this.camera.position.y + 10,
    //   this.camera.position.z + 10)
    if (this.props?.type === 'avatar') {
      // Animate speech
      if (this.speechAnimationRunning) { //TODO: Maybe should also check this.activeTrajectories, but it creates problems
        this.animateSpeechAnimationFrame();
      }
      // Randomly command blinks
      if (!this.blinkAnimationRunning && Math.random() < 0.004) {
        this.blinkAnimationRunning = true;
      }
      // Animate blinks
      if (this.blinkAnimationRunning) {
        this.animateBlinkAnimationFrame();
      }
    } else if (this.props?.type === 'voice') {
      this.animateVoiceAnimationFrame();
    }
    this.renderer.render(this.scene, this.camera)
  }

  loadGLTF(url) {
    return new Promise((resolve) => {
      const loader = new GLTFLoader()
      loader.load(url, (gltf) => resolve(gltf))
    })
  }

  renderPrimaryButtonIcon = () => {
    switch (this.state.avatarState) {
      case 'recording':
        return (
          // Paint 3 vertical bars, in the form of a histogram
          <svg id="histogram-icon" className={`rotate-180 text-white ${this.getButtonIconSize()}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <rect x="4" y={12 - this.state.audioHist[0] / 2} width="3" height={this.state.audioHist[0]} rx="1" fill="currentColor" />
            <rect x="10" y={12 - this.state.audioHist[1] / 2} width="3" height={this.state.audioHist[1]} rx="1" fill="currentColor" />
            <rect x="16" y={12 - this.state.audioHist[2] / 2} width="3" height={this.state.audioHist[2]} rx="1" fill="currentColor" />
          </svg>
        )
      case 'processing':
        return (
          // Paint a semicircular indicator using circle and stroke-dasharray
          <svg id="processing-icon" className={`text-white ${this.getButtonIconSize()} animate-spin`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
          </svg>
        )
      case 'speaking':
        return (
          // Paint a speaker icon
          <svg id="speaker-icon" className={`text-white ${this.getButtonIconSize()}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
            <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z" />
            <path d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z" />
          </svg>
        )
      case 'standby':
        return (
          // Paint a microphone icon
          <svg id="microphone-icon" className={`text-white ${this.getButtonIconSize()}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
          </svg>
        )
      default:
        return (
          <svg className={`text-white ${this.getButtonIconSize()}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
          </svg>
        )
    }
  }

  renderSecondaryButtonIcon = () => {
    return (
      <svg className={`${this.getButtonIconSize()} text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  }

  handleOnClickPrimaryButton = async () => {
    try {
      switch (this.state.avatarState) {
        case 'recording':
          consoleLogCustom('Stopping recording...');
          await this.stopRecording();
          consoleLogCustom('Recording stopped.');
          break;
        case 'processing':
          consoleLogCustom('Currently processing...');
          break;
        case 'speaking':
          consoleLogCustom('Currently speaking...');
          break;
        case 'standby':
          consoleLogCustom('Starting query...');
          await this.startQuery();
          consoleLogCustom('Query started.');
          break;
        default:
          consoleLogCustom('Unknown state:', this.state.avatarState);
          break;
      }
    } catch (error) {
      console.error('Error in handleOnClickPrimaryButton:', error);
    }
  }

  handleOnClickSecondaryButton = () => {
    this.settingsMenuOpen = !this.settingsMenuOpen;
    // Get settingsMenu element
    const settingsMenu = document.getElementsByName('settingsMenu')[0];
    // If settingsMenu is open, remove hidden class
    if (this.settingsMenuOpen) {
      settingsMenu.classList.remove('hidden');
    } else {
      settingsMenu.classList.add('hidden');
    }

    consoleLogCustom('this.settingsMenuOpen: ', this.settingsMenuOpen);
  }

  handleOnChangeLanguage = (event) => {
    this.language = event.target.value;
    const languageSelector = document.getElementById('language');
    languageSelector.value = this.language;
    const savedMessage = document.getElementsByName('saved-message')[0];
    savedMessage.classList.remove('hidden');
    setTimeout(() => {
      savedMessage.classList.add('hidden');
    }, 2000);
    consoleLogCustom('this.language: ', this.language);
    this.forceUpdate(); // Force update to render the new language. TODO: Find a better way to do this
  }

  getDefaultLanguage() {
    // Get language from props
    const defaultLanguage = this.props?.language ?? this.props.i18n.language;
    // Check if language is available
    if (AvailableLanguages.filter((availableLanguage) => availableLanguage.value === defaultLanguage).length === 0) {
      consoleLogCustom('Language not available. Using selected global language instead.');
      return this.props.i18n.language;
    } else {
      return defaultLanguage;
    }
  }

  getButtonPosition = (button) => {
    if (button === 'primary') {
      // Primary button is always on the bottom left corner
      if (this.props?.aid && this.props?.aid.startsWith('male')) {
        if (this.props?.sizeType === 'small') {
          return 'absolute left-0 bottom-1';
        } else if (this.props?.sizeType === 'medium') {
          return 'absolute left-1 bottom-2';
        } else if (this.props?.sizeType === 'large') {
          return 'absolute left-2 bottom-2';
        }
      } else {
        if (this.props?.sizeType === 'small') {
          return 'absolute left-0.5 bottom-2';
        } else if (this.props?.sizeType === 'medium') {
          return 'absolute left-2 bottom-4';
        } else if (this.props?.sizeType === 'large') {
          return 'absolute left-4 bottom-4';
        }
      }
    } else if (button === 'secondary') {
      // Secondary button is always on the bottom right corner
      if (this.props?.aid && this.props?.aid.startsWith('male')) {
        if (this.props?.sizeType === 'small') {
          return 'absolute right-0 bottom-1';
        } else if (this.props?.sizeType === 'medium') {
          return 'absolute right-1 bottom-2';
        } else if (this.props?.sizeType === 'large') {
          return 'absolute right-2 bottom-2';
        }
      } else {
        if (this.props?.sizeType === 'small') {
          return 'absolute right-0.5 bottom-2';
        } else if (this.props?.sizeType === 'medium') {
          return 'absolute right-2 bottom-4';
        } else if (this.props?.sizeType === 'large') {
          return 'absolute right-4 bottom-4';
        }
      }
    }
  }

  getButtonSize = () => {
    if (this.props?.sizeType === 'small') {
      return 'w-11 h-11';
    } else if (this.props?.sizeType === 'medium') {
      return 'w-14 h-14';
    } else if (this.props?.sizeType === 'large') {
      return 'w-14 h-14';
    }
  }

  getButtonIconSize = () => {
    if (this.props?.sizeType === 'small') {
      return 'w-7 h-7';
    } else if (this.props?.sizeType === 'medium') {
      return 'w-10 h-10';
    } else if (this.props?.sizeType === 'large') {
      return 'w-10 h-10';
    }
  }

  getLanguageSelectSize = () => {
    if (this.props?.sizeType === 'small') {
      return 'w-20 h-5';
    } else if (this.props?.sizeType === 'medium') {
      return 'w-28 h-8';
    } else if (this.props?.sizeType === 'large') {
      return 'w-40 h-10';
    }
  }

  getLanguageSelectTitleSize = () => {
    if (this.props?.sizeType === 'small') {
      return 'text-md';
    } else if (this.props?.sizeType === 'medium') {
      return 'text-lg';
    } else if (this.props?.sizeType === 'large') {
      return 'text-xl';
    }
  }

  getSettingsMenuTextSize = () => {
    if (this.props?.sizeType === 'small') {
      return 'text-xs';
    } else {
      return 'text-sm';
    }
  }

  render = () => (
    <div
      className='relative'>
      <div
        ref={this.mainViewRef}
        className="avatarView bg-transparent"
        style={{
          display: `${!this.props.showIFrame ? 'block' : 'none'}`,
          // height: calc(100vh - 100px);
        }}
      />
      <div className={this.getButtonPosition('primary')}>
        {/* <span class={(this.state.avatarState === 'standby' ? '' : 'hidden ') + "animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75 z-40"}></span> */}
        <button
          id="chatbot-primary-button"
          onClick={this.handleOnClickPrimaryButton}
          className={`flex flex-col items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-700 ${this.getButtonSize()}`}>
          {this.renderPrimaryButtonIcon()}
        </button>
      </div>
      <div className={this.getButtonPosition('secondary')}>
        {/* <span class={(this.state.avatarState === 'standby' ? '' : 'hidden ') + "animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75 z-40"}></span> */}
        <button
          id="chatbot-secondary-button"
          onClick={this.handleOnClickSecondaryButton}
          className={`flex flex-col items-center justify-center rounded-full bg-gray-400 hover:bg-gray-500 ${this.getButtonSize()}`}>
          {this.renderSecondaryButtonIcon()}
        </button>
      </div>
      {/* Render settings menu screen on top of all previous elements */}
      <div
        name="settingsMenu"
        className={'hidden absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 z-[600] flex flex-col items-center justify-center'} >
        {/* Render button to close menu on the top right corner */}
        <button
          onClick={this.handleOnClickSecondaryButton} // Close menu
          className='absolute top-4 right-4 flex flex-col items-center justify-center rounded-full bg-transparent w-6 h-6'>
          <svg className="w-6 h-6 text-gray-400 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {/* Render settings menu content */}
        <div className="flex flex-col items-center justify-start">
          <span className={"font-bold text-gray-900" + this.getLanguageSelectTitleSize()}>{this.props.t('chatbot_language', { lng: this.language.split('-')[0] })}</span>
          <select
            name="language"
            id="language"
            className={this.getLanguageSelectSize() + "rounded-md"}
            defaultValue={this.getDefaultLanguage()}
            onChange={this.handleOnChangeLanguage}>
            {AvailableLanguages.map((language) => {
              return (
                <option value={language['value']} key={language['value']}>{this.props.t('languages_' + language['value'].replace('-', '_'), { lng: this.language.split('-')[0], rgn: '(US)' })}</option>
              )
            })}
          </select>
          <span
            className={"hidden text-gray-500 " + this.getSettingsMenuTextSize()}
            name="saved-message">{this.props.t('chatbot_setting_saved', { lng: this.language.split('-')[0] })}</span>
        </div>
        <div className={'absolute bottom-2 right-2 pl-2 ' + this.getSettingsMenuTextSize()}>
          Powered by
          {/* Open link in new tab */}
          <a
            className='ml-1'
            href="https://chattier.dev"
            target='_blank'
            rel="noreferrer" >
            <b>Chattier</b>
          </a>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(AvatarView); // https://react.i18next.com/latest/withtranslation-hoc
