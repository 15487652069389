import { useTranslation } from 'react-i18next';
import React from 'react';

const DomainsTable = ({ allowedDomains }) => {
  const { t } = useTranslation();
  return (
    <div className='overflow-x-auto'>
      <div className='inline-block min-w-full overflow-hidden'>
        <table className='table-auto w-full'>
          <thead>
            <tr>
              <th className='flex items-center pl-9 h-20 bg-blueGray-100 text-left'>
                <a
                  className='inline-block mr-4 text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'
                  href='#'
                >
                  {t('new_chatbot_domain')}
                </a>
                <div>
                  <svg
                    className='my-1'
                    width={7}
                    height={4}
                    viewBox='0 0 7 4'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.898704 3.84541C0.692525 4.05153 0.359292 4.05153 0.154634 3.84541C-0.0507836 3.6393 -0.0523052 3.30629 0.154635 3.10017L3.12787 0.154586C3.33329 -0.0515278 3.66576 -0.0515278 3.8727 0.154586L6.84594 3.10017C7.05135 3.30552 7.05135 3.6393 6.84594 3.84541C6.63976 4.05153 6.30652 4.05153 6.10187 3.84541L3.49915 1.42973L0.898704 3.84541Z'
                      fill='#9E9FA0'
                    />
                  </svg>
                  <svg
                    className='my-1'
                    width={7}
                    height={4}
                    viewBox='0 0 7 4'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.10129 0.154586C6.30747 -0.0515286 6.64071 -0.0515286 6.84537 0.154586C7.05078 0.3607 7.0523 0.693713 6.84537 0.899827L3.87213 3.84541C3.66671 4.05153 3.33424 4.05153 3.1273 3.84541L0.154064 0.899827C-0.0513545 0.694482 -0.0513545 0.3607 0.154064 0.154586C0.360242 -0.0515286 0.693476 -0.0515286 0.898133 0.154586L3.50085 2.57027L6.10129 0.154586Z'
                      fill='#9E9FA0'
                    />
                  </svg>
                </div>
              </th>
              <th className='p-5 h-20 bg-blueGray-100'>
              </th>
            </tr>
          </thead>
          <tbody>
            {allowedDomains.map((url, i) => {
              return (
                <tr key={`key_${i}`}>
                  <td className='p-0'>
                    <div className={(i % 2 == 0) ?
                      'flex items-center pl-9 h-10 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-xl rounded-bl-xl' :
                      'flex items-center pl-9 h-10 bg-white rounded-tl-xl rounded-bl-xl'}>
                      <span className='text-md font-heading font-medium'>
                        {url}
                      </span>
                    </div>
                  </td>
                  <td className='p-0'>
                    <div className={(i % 2 == 0) ?
                      'flex items-center justify-center p-5 h-10 text-center bg-blueGray-50 border-t border-b border-r border-gray-100 rounded-tr-xl rounded-br-xl' :
                      'flex items-center justify-center p-5 h-10 text-center bg-white rounded-tr-xl rounded-br-xl'}>
                      <span className='text-md text-darkBlueGray-400 font-heading'>
                        {''}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DomainsTable;