import React from 'react';
import { Link } from 'react-router-dom';
import chattierLogo from '../assets/uinel-assets/logos/chattier_logo_black.svg';
import { useTranslation } from 'react-i18next';

function Footer() {

  const { t } = useTranslation();

  return (
    <section className='py-7 2xl:py-7 bg-blueGray-50'>
      <div className='container px-4 mx-auto'>
        <div className='flex w-full items-center justify-between'>
          <div className='hidden xl:flex items-center justify-left'>
            <Link to='/'>
              <img
                className='h-12'
                src={chattierLogo}
                alt='Chattier logo'
              />
            </Link>
            <Link
              id='footer-pricing-button'
              to='/pricing'
              className='ml-7 px-7 font-heading text-darkBlueGray-300 hover:text-darkBlueGray-400'
            >
              {t('footer_pricing')}
            </Link>
            <Link
              id='footer-contact-button'
              to='/contact'
              className='px-7 font-heading text-darkBlueGray-300 hover:text-darkBlueGray-400'
            >
              {t('footer_contact')}
            </Link>
            <Link
              id='footer-about-button'
              to='/about'
              className='px-7 font-heading text-darkBlueGray-300 hover:text-darkBlueGray-400'
            >
              {t('footer_about')}
            </Link>
            <button
              id='footer-affiliate-button'
              onClick={() => { window.location.href = 'https://chattier.getrewardful.com/signup' }}
              className='px-7 font-heading text-darkBlueGray-300 hover:text-darkBlueGray-400'>
              {t('footer_affiliate')}
            </button>
            <Link
              id='footer-privacy-button'
              to='/privacy'
              className='px-7 font-heading text-darkBlueGray-300 hover:text-darkBlueGray-400'
            >
              {t('footer_privacy')}
            </Link>
            <a
              id='footer-scroll-to-top-button'
              className='px-7'
              href='#'>
              <img
                src='uinel-assets/images/footers/scroll-down.svg'
                alt='Icon for scrolling to the top of the page'
              />
            </a>
            <a
              id='footer-product-hunt-badge'
              href="https://www.producthunt.com/posts/chattier?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chattier"
              target="_blank"
            >
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=411591&theme=neutral"
                alt="Chattier - Create&#0032;intelligent&#0032;support&#0032;chatbots&#0032;to&#0032;embed&#0032;on&#0032;any&#0032;website | Product Hunt"
                style={{ width: '250px', height: '54px' }}
                width="250"
                height="54"
              />
            </a>
          </div>
          <div className='flex items-center justify-center w-full xl:w-auto'>
            <p className='text-sm tracking-widest text-darkBlueGray-300 ml-4'>
              © 2024 Chattier
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;