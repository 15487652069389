import React from 'react';
import CustomTabSelector from './CustomTabSelector';
import { useTranslation } from 'react-i18next';

const ChatbotDataTabSelector = ({ dataSourceType, setDataSourceType }) => {
  const { t } = useTranslation();
  const chatbotOptions = [
    { value: 'text', label: t('new_chatbot_text') },
    { value: 'website', label: t('new_chatbot_website') },
  ];
  return (
    <CustomTabSelector options={chatbotOptions} selected={dataSourceType} setSelected={setDataSourceType} />
  );
};

export default ChatbotDataTabSelector;