import { useTranslation } from 'react-i18next';
import React from 'react';
import WarningIcon from './WarningIcon';
import DomainsTable from './DomainsTable';

function getAllowedDomainsMessage(domainCount) {
  const { t } = useTranslation();
  if (domainCount === 0) {
    return t('new_chatbot_no_domains');
  } else if (domainCount === 1) {
    return t('new_chatbot_one_domain');
  } else {
    return `${domainCount} ` + t('new_chatbot_n_domains');
  }
}

const ChatbotDomainsEditor = ({ allowedDomainsCount, noDomainsWarning, handleAllowedDomainChanged, handleClickAddAllowedDomain, allowedDomains }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='pl-0 sm:pl-10 my-3 flex'>
        <span className='text-indigo-500'>
          {getAllowedDomainsMessage(allowedDomainsCount)}
        </span>
      </div>
      {noDomainsWarning && (
        <div className="flex mb-1">
          <WarningIcon />
          <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_no_domains_warning')}</p>
        </div>
      )}
      <div className='border-t border-gray-100' />
      <h1 className='pl-0 sm:pl-10 my-3 text-lg font-bold'>
        {t('new_chatbot_install')}
      </h1>
      <div className='flex items-center justify-start'>
        <div className='pl-0 sm:pl-10 pr-4 md:pr-9 w-[40rem]'>
          <div>
            <input
              name='allowed-domain-input'
              className='w-full py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
              type='text'
              placeholder={t('new_chatbot_eg') + ' mycompany.com'}
              onChange={handleAllowedDomainChanged}
            />
          </div>
        </div>
        <div className=''>
          <button
            onClick={handleClickAddAllowedDomain}
            className='block py-3 px-3 w-30 leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
          >
            {t('new_chatbot_add_domain')}
          </button>
        </div>
      </div>
      <div className='mt-3 border-t border-gray-100' />
      <DomainsTable allowedDomains={allowedDomains} />
    </div>
  );
};

export default ChatbotDomainsEditor;