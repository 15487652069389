import { useTranslation } from 'react-i18next';
import React from 'react';

const ChatMinimizeSelector = ({ allowToMinimize, setAllowToMinimize }) => {
  const { t } = useTranslation();
  return (
    <div className='flex' >
      <input
        className='ml-9 w-6 h-6 accent-indigo-600 bg-white border-2 border-darkbluegray-50 rounded-lg'
        type='checkbox'
        name='bubble-checkbox'
        id='bubble-checkbox'
        checked={allowToMinimize}
        onChange={() => setAllowToMinimize(!allowToMinimize)}
      />
      <span className='ml-2'>
        {t('new_chatbot_minimization_description')}
      </span>
    </div>
  );
};

export default ChatMinimizeSelector;