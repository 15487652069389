import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../Firebase';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { consoleLogCustom, getBackendUrl } from '../components/Utils';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from "firebase/analytics";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ProcessSignIn(props) {

  const { t } = useTranslation();

  React.useEffect(() => {
    let ignore = false;
    if (!ignore) {
      // When loading page, check if url contains sign in link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt(t('signin_email_confirmation'));
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then(async (result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            const title = document.getElementsByName('title')[0];
            title.innerHTML = t('signin_successful');
            const subtitle = document.getElementsByName('subtitle')[0];
            subtitle.innerHTML = t('signin_successful_message');
            // Add user to unsubscribed customers (if not already there)
            const response = await fetch(getBackendUrl() + "/add-new-unsubscribed-customer", {
              method: "POST",
              headers: {
                'uid': result.user.uid,
                'rid': Rewardful.referral ? Rewardful.referral : null,
                'email': result.user.email,
              },
            });
            const data = await response.json();
            consoleLogCustom(data)
            // Log a login event to Google Analytics
            const analytics = getAnalytics();
            logEvent(analytics, 'login', {
              method: 'email',
              uid: result.user.uid,
            });
            if (data.newUser) {
              // Log a new registered user event to Google Analytics
              logEvent(analytics, 'sign_up', {
                method: 'email',
                uid: result.user.uid,
              });
            }
          })
          .catch((error) => {
            consoleLogCustom(error);
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            const title = document.getElementsByName('title')[0];
            title.innerHTML = t('signin_failed');
            const subtitle = document.getElementsByName('subtitle')[0];
            subtitle.innerHTML = t('signin_failed_message');
            // Log a failed login event to Google Analytics
            const analytics = getAnalytics();
            logEvent(analytics, 'failed_login', {
              method: 'email',
              error: error.code,
            });
          });
      }
    }
    return () => { ignore = true; }
  }, []);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Navbar user={props.user} logout={props.logout} />
      <section className='pb-24'>
        <div className='pt-24 pb-64 xl:pt-52 lg:pb-80 bg-blueGray-100 text-center rounded-b-10xl'>
          <div className='container px-4 mx-auto'>
            <h2 className='mb-12 text-9xl md:text-10xl xl:text-11xl leading-tight font-heading font-medium' name='title' />
            <p className='text-xl text-gray-400' name='subtitle' />
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

