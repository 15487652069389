import { useTranslation } from 'react-i18next';
import React from 'react';

const sizeOptions = [
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];


const SizeSelector = ({ selectedSize, setSelectedSize }) => {
  const { t } = useTranslation();
  return (
    <div className='mb-3 ml-0 sm:ml-9 grid grid-cols-1 gap-0'>
      {sizeOptions.map((option) => (
        <label key={option.value}>
          <input
            className='ml-3'
            type='radio'
            name='inline-radio'
            value={option.value}
            checked={selectedSize === option.value}
            onChange={() => setSelectedSize(option.value)}
          />
          <span className='ml-3'>{t(`new_chatbot_${option.value}`)}</span>
        </label>
      ))}
    </div>
  );
};

export default SizeSelector;