import { consoleLogCustom } from "./Utils";

// JS ChatHistory class to manage chat history messages
export class ChatHistory {
    constructor() {
        this.history = [];
        this.n_chars = [];
    }

    async addQuery(query) {
        this.history.push({ role: 'user', content: query });
        this.n_chars.push(query.length);
    }

    async addResponse(response) {
        this.history.push({ role: 'assistant', content: response });
        this.n_chars.push(response.length);
        this.limitHistory();
    }

    // Add a new message to the chat history
    async addQueryAndResponse(message) {
        this.history.push({ role: 'user', content: message.query });
        this.history.push({ role: 'assistant', content: message.answer });
        this.n_chars.push(message.query.length)
        this.n_chars.push(message.answer.length)
        this.limitHistory();
    }

    limitHistory() {
        const total_n_chars = this.n_chars.reduce((a, b) => a + b, 0);
        while (total_n_chars > 8000 && this.history.length >= 2) {
            // Remove oldest pair (user + assistant)
            this.history.splice(0, 2);
            this.n_chars.splice(0, 2);
            
            // Recalculate total after removal
            const new_total = this.n_chars.reduce((a, b) => a + b, 0);
            if (new_total <= 8000) break;
        }
    }

    // Get the chat history messages
    getHistory() {
        return this.history;
    }

    clone() {
        const newHistory = new ChatHistory();
        newHistory.history = [...this.history];
        newHistory.n_chars = [...this.n_chars];
        return newHistory;
    }
}
