import React, { useRef, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import chattierLogo from '../assets/uinel-assets/logos/chattier_logo_white.svg';
import { useTranslation } from 'react-i18next';
import { ReducedAvailableLanguages } from './AvailableLanguages';

const BurgerMenu = forwardRef(({ burgerMenuOpen, setBurgerMenuOpen, handleClickSignOut, user, burgerMenuLanguageSelectMode, setBurgerMenuLanguageSelectMode }, ref) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const burgerMenu = document.getElementById('burger-menu');
    if (burgerMenuOpen) {
      burgerMenu.classList.remove('hidden');
    } else {
      burgerMenu.classList.add('hidden');
    }
  }, [burgerMenuOpen]);

  return (
    <div
      ref={ref}
      id='burger-menu'
      className='hidden navbar-menu fixed top-0 right-0 bottom-0 w-5/6 max-w-sm z-50'>
      <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
      <nav className='relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto'>
        <div className='flex items-center mt-10 mb-10 px-10'>
          <button onClick={() => navigate('/')}>
            <img className='h-12' src={chattierLogo} alt='Chattier logo' />
          </button>
        </div>
        <div>
          <ul>
            {!burgerMenuLanguageSelectMode && (
              <div>
                <li className='mb-1 px-10'>
                  <button
                    id='burger-menu-pricing-button'
                    onClick={() => navigate('/pricing')}
                    className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                    <span>{t('navbar_pricing')}</span>
                  </button>
                </li>
                <li className='mb-1 px-10'>
                  <button
                    id='burger-menu-contact-button'
                    onClick={() => navigate('/contact')}
                    className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                    <span>{t('navbar_contact')}</span>
                  </button>
                </li>
                <li className='mb-1 px-10'>
                  <button
                    id='burger-menu-about-button'
                    onClick={() => navigate('/about')}
                    className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                    <span>{t('navbar_about')}</span>
                  </button>
                </li>
                <li className='mb-1 px-10'>
                  <button
                    id='burger-menu-affiliate-button'
                    onClick={() => {
                      window.location.href =
                        'https://chattier.getrewardful.com/signup';
                    }}
                    className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                    <span>{t('navbar_affiliate')}</span>
                  </button>
                </li>
                {user?.email && (
                  <>
                    <li className='mb-1 px-10'>
                      <button
                        id='burger-menu-dashboard-button'
                        onClick={() => navigate('/dashboard')}
                        className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                        <span>{t('navbar_my_chatbots')}</span>
                      </button>
                    </li>
                    <li className='mb-1 px-10'>
                      <button
                        id='burger-menu-profile-button'
                        onClick={() => navigate('/profile')}
                        className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                        <span>{t('navbar_profile')}</span>
                      </button>
                    </li>
                    <li className='mb-1 px-10'>
                      <button
                        id='burger-menu-signout-button'
                        onClick={handleClickSignOut}
                        className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                        <span>{t('navbar_signout')}</span>
                      </button>
                    </li>
                  </>
                )}
                {!user?.email && (
                  <li className='mb-1 px-10'>
                    <button
                      onClick={() => navigate('/signin')}
                      className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-lg w-full'>
                      <span>{t('navbar_signin')}</span>
                    </button>
                  </li>
                )}
              </div>
            )}
            {burgerMenuLanguageSelectMode && (
              ReducedAvailableLanguages.map((language, index) => (
                <li key={index} className='mb-1 px-10'>
                  <button
                    key={index}
                    onClick={() => {
                      i18n.changeLanguage(language['value']);
                    }}
                    className='flex items-center pl-8 py-3 text-body text-lg rounded-full hover:shadow-lg w-full'
                    role='menuitem'
                    tabIndex='-1'
                    id={`menu-item-${index}`}>
                    <img
                      className='w-7 mr-2'
                      src={language['flag']}
                      alt='Language flag'
                    />
                    {t('languages_' + language['value'])}
                    {i18n.language.split('-')[0] === language['value'] && (
                      <svg
                        className='ml-1 w-4 h-4'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M4.5 12.75l6 6 9-13.5'
                        />
                      </svg>
                    )}
                  </button>
                </li>
              ))
            )}
          </ul>
        </div>
      </nav>
      <div className='absolute top-0 w-full h-20 bg-white' />
      <div className='absolute top-20 w-full h-8 bg-gradient-to-b from-white to-transparent' />
      <button
        onClick={() => setBurgerMenuOpen(false)}
        className='navbar-close absolute top-5 right-5 p-6'>
        <div className='absolute top-7'>
          <span className='absolute w-px h-6 bg-black transform -rotate-45' />
          <span className='absolute w-px h-6 bg-black transform rotate-45' />
        </div>
      </button>
      <button
        id='burger-menu-language-button'
        type='button'
        className='absolute top-5 left-5 p-6 bg-transparent'
        aria-expanded='true'
        aria-haspopup='true'
        onClick={() => setBurgerMenuLanguageSelectMode(!burgerMenuLanguageSelectMode)}>
        {!burgerMenuLanguageSelectMode && (  
        <svg
          className='text-body w-8 h-8'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802'
          />
        </svg>
        )}
        {burgerMenuLanguageSelectMode && (
          <svg 
            className='text-body w-8 h-8'
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#0c0e12">
              <path
                strokeLinecap="round"
                strokeLinejoin='round' 
                d="M120-240v-80h520v80H120Zm664-40L584-480l200-200 56 56-144 144 144 144-56 56ZM120-440v-80h400v80H120Zm0-200v-80h520v80H120Z"
              />
          </svg>
        )}
      </button>
    </div>
  );
});

export default BurgerMenu;