import React from 'react';

const CustomTabSelector = ({ options, selected, setSelected }) => {
  return (
    <div className='pl-0 sm:pl-10 mb-4 mt-2'>
      <div className='flex items-start border-t border-gray-100'>
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => setSelected(option.value)}
            className={selected === option.value ?
              'inline-block py-2 w-24 sm:w-32 text-indigo-500 hover:text-indigo-600 font-heading font-medium border-t-2 border-indigo-500 hover:border-indigo-600' :
              'inline-block py-2 w-24 sm:w-32 text-gray-300 hover:text-gray-400 font-heading font-medium border-t-2 border-gray-300 hover:border-gray-400'}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomTabSelector;