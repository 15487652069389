import { useTranslation } from 'react-i18next';
import React from 'react';
import ChatbotTextEditor from './ChatbotTextEditor';
import WarningIcon from './WarningIcon';
import ChatbotDataTabSelector from './ChatbotDataTabSelector';
import AutoUrlSelector from './AutoUrlSelector';
import ManualUrlSelector from './ManualUrlSelector';

const ChatbotDataEditor = ({ dataSourceType, setDataSourceType, noSourcesWarning, textSource, handleTextSourceChanged, selectedUrlsCount, handleClickGetUrls, isDiscoveringUrls, targetUrl, handleTargetUrlChanged, sitemapUrl, handleSitemapUrlChanged, manualUrl, handleManualUrlChanged, handleClickAddManualUrl, globalCheckboxChecked, handleGlobalCheckboxChanged, filteredUrls, setFilteredUrls }) => {
  const { t } = useTranslation();
  return (
    <div>
      <ChatbotDataTabSelector dataSourceType={dataSourceType} setDataSourceType={setDataSourceType} />
      {noSourcesWarning && (
        <div className="flex mb-1">
          <WarningIcon />
          <p className="pl-0 sm:pl-10 text-gray-500 text-sm">{t('new_chatbot_no_sources_warning')}</p>
        </div>
      )}
      {dataSourceType === 'text' && (
        <ChatbotTextEditor textSource={textSource} handleTextSourceChanged={handleTextSourceChanged} />
      )}
      {dataSourceType === 'website' && (
        <div>
          <AutoUrlSelector
            selectedUrlsCount={selectedUrlsCount}
            handleClickGetUrls={handleClickGetUrls}
            isDiscoveringUrls={isDiscoveringUrls}
            targetUrl={targetUrl}
            handleTargetUrlChanged={handleTargetUrlChanged}
            sitemapUrl={sitemapUrl}
            handleSitemapUrlChanged={handleSitemapUrlChanged}
          />
          <ManualUrlSelector
            manualUrl={manualUrl}
            handleManualUrlChanged={handleManualUrlChanged}
            handleClickAddManualUrl={handleClickAddManualUrl}
            globalCheckboxChecked={globalCheckboxChecked}
            handleGlobalCheckboxChanged={handleGlobalCheckboxChanged}
            filteredUrls={filteredUrls}
            setFilteredUrls={setFilteredUrls}
          />
        </div>
      )}
    </div>
  );
};

export default ChatbotDataEditor;