import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDomainFromUrl } from '../components/Domain';
import { consoleLogCustom, countTokens, getBackendUrl } from '../components/Utils';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { getLanguageFromReducedLanguage } from '../components/AvailableLanguages';
import { useTranslation } from 'react-i18next';
import ChatbotAppearanceEditor from '../components/ChatbotAppearanceEditor';
import ChatbotInformationEditor from '../components/ChatbotInformationEditor';
import ChatbotDataEditor from '../components/ChatbotDataEditor';
import ChatbotDomainsEditor from '../components/ChatbotDomainsEditor';
import NewChatbotTitleSection from '../components/NewChatbotTitleSection';
import ChatbotPersonalizationStepsNavbar from '../components/ChatbotPersonalizationStepsNavbar';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function UpdateChatbot(props) {

  const { t, i18n } = useTranslation();

  const [configStep, setConfigStep] = React.useState(0);
  const [targetUrl, setTargetUrl] = React.useState('');
  const [sitemapUrl, setSitemapUrl] = React.useState('');
  const [urls, setUrls] = React.useState([]);
  const [filteredUrls, setFilteredUrls] = React.useState([]);
  const [globalCheckboxChecked, setGlobalCheckboxChecked] = React.useState(false);
  const [chatbotName, setChatbotName] = React.useState('');
  const [chatbotDescription, setChatbotDescription] = React.useState('');
  const [chatbotInstructions, setChatbotInstructions] = React.useState(t('new_chatbot_instructions_eg'));
  const [chatbotLanguageModel, setChatbotLanguageModel] = React.useState('gpt-4o-mini-2024-07-18');
  const [chatbotLanguage, setChatbotLanguage] = React.useState(getLanguageFromReducedLanguage(i18n.language));
  const [textSource, setTextSource] = React.useState('');
  const [selectedUrls, setSelectedUrls] = React.useState([]);
  const [selectedUrlsCount, setSelectedUrlsCount] = React.useState(0);
  const [allowedDomainsCount, setAllowedDomainsCount] = React.useState(0);
  const [manualUrl, setManualUrl] = React.useState('');
  const [allowedDomain, setAllowedDomain] = React.useState('');
  const [allowedDomains, setAllowedDomains] = React.useState([]);
  const [chatbotType, setChatbotType] = React.useState('avatar');
  const [avatarIdentity, setAvatarIdentity] = React.useState('female1');
  const [selectedAnimation, setSelectedAnimation] = React.useState('sphere1');
  const [dataSourceType, setDataSourceType] = React.useState('text');
  const [showBubble, setShowBubble] = React.useState(true);
  const [selectedChatSize, setSelectedChatSize] = React.useState('medium');
  const [selectedAvatarSize, setSelectedAvatarSize] = React.useState('medium');
  const [selectedVoiceSize, setSelectedVoiceSize] = React.useState('medium');
  const [selectedColor, setSelectedColor] = React.useState('indigo');
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [isDiscoveringUrls, setIsDiscoveringUrls] = React.useState(false);
  const [instructionsTooLongWarning, setInstructionsTooLongWarning] = React.useState(false);
  const [instructionsEmptyWarning, setInstructionsEmptyWarning] = React.useState(false);
  const [nameEmptyWarning, setNameEmptyWarning] = React.useState(false);
  const [instructionsTokens, setInstructionsTokens] = React.useState(0);
  const [noSourcesWarning, setNoSourcesWarning] = React.useState(false);
  const [noDomainsWarning, setNoDomainsWarning] = React.useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Add constructor here
  React.useEffect(() => {
    async function loadChatbotData() {
      // If updating an existing chatbot, load the chatbot data
      if (!props.new) {
        // Load the chatbot data
        const chatbotId = searchParams.get('cid');
        if (chatbotId) {
          const chatbotRef = doc(db, "chatbots", chatbotId);
          const chatbotSnap = await getDoc(chatbotRef);
          if (chatbotSnap.exists()) {
            const chatbotObject = chatbotSnap.data();
            if (chatbotObject) {
              // Chatbot found
              setChatbotName(chatbotObject['name']);
              setChatbotDescription(chatbotObject['description']);
              setChatbotInstructions(chatbotObject['instructions'] ?? t('new_chatbot_instructions_eg'));
              setChatbotLanguageModel(chatbotObject['language_model'] ?? 'gpt-4o-mini-2024-07-18');
              setChatbotLanguage(chatbotObject['language'] ?? getLanguageFromReducedLanguage(i18n.language));
              setAllowedDomains(chatbotObject['allowed_domains']);
              setChatbotType(chatbotObject['chatbot_type']);
              if (chatbotObject['chatbot_type'] === 'avatar') {
                setAvatarIdentity(chatbotObject['avatar_identity']);
                setSelectedAvatarSize(chatbotObject['chatbot_size']);
              } else if (chatbotObject['chatbot_type'] === 'voice') {
                setSelectedVoiceSize(chatbotObject['chatbot_size']);
                setSelectedAnimation(chatbotObject['voice_animation']);
              } else if (chatbotObject['chatbot_type'] === 'chat') {
                setSelectedChatSize(chatbotObject['chatbot_size']);
                setShowBubble(chatbotObject['chatbot_bubble'] === true);
                setSelectedColor(chatbotObject['chatbot_color']);
              }
              setDataSourceType(chatbotObject['data_source']);
              setTextSource(chatbotObject['text_source']);
              const urlmaplist = []
              for (const url of chatbotObject['selected_urls']) {
                const urlmap = new Map()
                urlmap.set('url', url)
                urlmap.set('selected', true)
                urlmaplist.push(urlmap)
              }
              setUrls(urlmaplist);
              setFilteredUrls(urlmaplist);
            } else {
              // Chatbot not found
              navigate('/dashboard');
            }
          }
        } else {
          // No chatbot id specified
          consoleLogCustom('No chatbot id specified');
          navigate('/dashboard');
        }
      }
    }
    loadChatbotData();
  }, []);

  function handleClickPrevious() {
    setConfigStep((configStep - 1 + 4) % 4);
  }

  async function handleClickNext() {
    if (configStep === 0) {
      setConfigStep((configStep + 1) % 4);
    } else if (configStep === 1) {
      var instructionsTooLong = false;
      var instructionsEmpty = false;
      var nameEmpty = false;
      // Check that instructions are no longer than 512 tokens
      setIsButtonLoading(true);
      const tokens = await countTokens(props.user?.uid, chatbotInstructions);
      setIsButtonLoading(false);
      setInstructionsTokens(tokens);
      if (tokens > 512) {
        consoleLogCustom('Instructions are longer than 512 tokens');
        instructionsTooLong = true;
      } else {
        instructionsTooLong = false;
      }
      setInstructionsTooLongWarning(instructionsTooLong);
      // Check that instructions are not empty
      if (chatbotInstructions === '') {
        consoleLogCustom('Instructions are empty');
        instructionsEmpty = true;        
      } else {
        instructionsEmpty = false;
      }
      setInstructionsEmptyWarning(instructionsEmpty);
      // Check that name is not empty
      if (chatbotName === '') {
        consoleLogCustom('Name is empty');
        nameEmpty = true;
      } else {
        nameEmpty = false;
      }
      setNameEmptyWarning(nameEmpty);
      // Only go to next step if no warnings are raised
      if (!instructionsTooLong && !instructionsEmpty && !nameEmpty) {
        setConfigStep((configStep + 1) % 4);
      }
    } else if (configStep === 2) {
      var noSources = false;
      // Check that there is at least one data source
      // TODO: Allow to use both a text source and a url source
      if (dataSourceType === 'text' && textSource === '') {
        consoleLogCustom('No text source');
        noSources = true;
      } else if (dataSourceType === 'website' && selectedUrlsCount === 0) {
        consoleLogCustom('No urls selected');
        noSources = true;
      }
      setNoSourcesWarning(noSources);
      // Only go to next step if no warnings are raised
      if (!noSources) {
        setConfigStep((configStep + 1) % 4);
      }
    } else if (configStep === 3) {
      var noDomains = false;
      // Check that there is at least one allowed domain
      if (allowedDomainsCount === 0) {
        consoleLogCustom('No allowed domains');
        noDomains = true;
      }
      setNoDomainsWarning(noDomains);
      // Only go to next step if no warnings are raised
      if (!noDomains) {
        // Update (or create) chatbot
        handleUpdateChatbot();
      }
    }
  }

  function handleChatbotNameChanged(event) {
    setChatbotName(event.target.value);
  }

  function handleChatbotDescriptionChanged(event) {
    setChatbotDescription(event.target.value);
  }

  function handleChatbotInstructionsChanged(event) {
    setChatbotInstructions(event.target.value);
  }

  function handleChatbotLanguageModelChanged(event) {
    setChatbotLanguageModel(event.target.value);
  }

  function handleChatbotLanguageChanged(event) {
    setChatbotLanguage(event.target.value);
  }

  function handleTextSourceChanged(event) {
    setTextSource(event.target.value);
  }

  function handleTargetUrlChanged(event) {
    setTargetUrl(event.target.value);
    setFilteredUrls(urls.filter(site => site.get('url').startsWith(event.target.value)));
  }

  function handleSitemapUrlChanged(event) {
    setSitemapUrl(event.target.value);
  }

  function handleManualUrlChanged(event) {
    setManualUrl(event.target.value);
  }

  function handleAllowedDomainChanged(event) {
    setAllowedDomain(event.target.value);
  }

  function handleGlobalCheckboxChanged(event) {
    // update all urls in the filtered list
    setFilteredUrls(filteredUrls.map(site => site.set('selected', event.target.checked)));
    // also update those urls in the complete list
    setUrls(urls.map(site => {
      if (site.get('url').startsWith(targetUrl)) {
        site.set('selected', event.target.checked);
      }
      return site;
    }));
    setGlobalCheckboxChecked(event.target.checked);
  }

  async function handleUpdateChatbot() {
    setIsButtonLoading(true);
    consoleLogCustom('Language: ', chatbotLanguage);
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(getBackendUrl() + "/update-chatbot", {
      method: "POST",
      body: JSON.stringify({
        chatbotId: props.new ? null : searchParams.get('cid'),
        chatbotName: chatbotName,
        chatbotDescription: chatbotDescription,
        chatbotInstructions: chatbotInstructions,
        chatbotLanguageModel: chatbotLanguageModel,
        chatbotLanguage: chatbotLanguage,
        dataSourceType: dataSourceType,
        textSource: textSource,
        selectedUrls: selectedUrls,
        allowedDomains: allowedDomains,
        chatbotType: chatbotType,
        avatarIdentity: chatbotType === 'avatar' ? avatarIdentity : null,
        voiceAnimation: chatbotType === 'voice' ? selectedAnimation : null,
        chatbotSize: chatbotType === 'avatar' ? selectedAvatarSize : chatbotType === 'voice' ? selectedVoiceSize : selectedChatSize,
        chatbotBubble: chatbotType === 'chat' ? showBubble : null,
        chatbotColor: chatbotType === 'chat' ? selectedColor : null,
        chatbotTitle: '',
        chatbotSubtitle: '',
        chatbotWelcomeMessage: '',
        chatbotApiKey: '',
        subscriptionLineItemId: '',
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'uid': props.user?.uid,
      },
    });
    const data = await response.json();
    setIsButtonLoading(false);
    navigate('/dashboard');
  }

  async function handleClickGetUrls() {
    consoleLogCustom(targetUrl);
    consoleLogCustom(sitemapUrl);
    if (targetUrl) {
      setIsDiscoveringUrls(true);
      // get urls from sitemap
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(getBackendUrl() + "/get-urls", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'target-url': targetUrl,
          'sitemap-url': sitemapUrl,
          'uid': props.user?.uid,
        },
      });
      const data = await response.json();
      consoleLogCustom(data);
      // Update urls, an array of maps with keys 'url' and 'selected'
      setUrls(data['urls'].map(url => {
        const urlmap = new Map()
        urlmap.set('url', url)
        urlmap.set('selected', true)
        return urlmap
      }));
      const filteredData = data['urls'].filter(url => url.startsWith(targetUrl));
      setFilteredUrls(filteredData.map(url => {
        const urlmap = new Map()
        urlmap.set('url', url)
        urlmap.set('selected', true)
        return urlmap
      }));
      setIsDiscoveringUrls(false);
    } else {
      // Raise warning window
      consoleLogCustom('Please enter a target url');
    }
  }

  function handleClickAddManualUrl() {
    consoleLogCustom(manualUrl);
    // Add manual url to urls
    const urlmap = new Map()
    urlmap.set('url', manualUrl)
    urlmap.set('selected', true)
    setUrls(urls.concat([urlmap]));
    // Add manual url to filteredUrls
    setFilteredUrls(filteredUrls.concat([urlmap]));
    // Clear manual url input
    setManualUrl('');
    // Clear actual input text
    document.getElementsByName('manual-url-input')[0].value = '';
  }

  function handleClickAddAllowedDomain() {
    consoleLogCustom(getDomainFromUrl(allowedDomain));
    // Add allowed url to allowedDomains
    setAllowedDomains(allowedDomains.concat([getDomainFromUrl(allowedDomain)]));
    // Clear allowed url input
    setAllowedDomain('');
    // Clear actual input text
    document.getElementsByName('allowed-domain-input')[0].value = '';
  }

  React.useEffect(() => {
    // update selected urls when filtered urls change
    // Get all selected urls from array filteredUrls, only those that are selected
    setSelectedUrls(filteredUrls.filter(url => url.get('selected')).map(url => url.get('url')));
    setSelectedUrlsCount(filteredUrls.filter(url => url.get('selected')).length);
  }, [filteredUrls]);

  React.useEffect(() => {
    // update allowed domains count when allowed domains change
    setAllowedDomainsCount(allowedDomains.length);
  }, [allowedDomains]);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <NewChatbotTitleSection props={props} />
        <section className='py-8 bg-blueGray-50'>
          <div className='px-4 mx-auto max-w-screen-lg'>
            <div className='p-7 pb-16 pt-12 bg-blueGray-100 rounded-5xl'>
              <ChatbotPersonalizationStepsNavbar 
                configStep={configStep}
                handleClickPrevious={handleClickPrevious}
                handleClickNext={handleClickNext}
                isButtonLoading={isButtonLoading}
                isNewChatbot={props.new}
              />
              {configStep == 0 && (
                <ChatbotAppearanceEditor
                  chatbotType={chatbotType}
                  setChatbotType={setChatbotType}
                  user={props.user}
                  selectedChatSize={selectedChatSize}
                  setSelectedChatSize={setSelectedChatSize}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  selectedAvatarSize={selectedAvatarSize}
                  setSelectedAvatarSize={setSelectedAvatarSize}
                  avatarIdentity={avatarIdentity}
                  setAvatarIdentity={setAvatarIdentity}
                  selectedVoiceSize={selectedVoiceSize}
                  setSelectedVoiceSize={setSelectedVoiceSize}
                  selectedAnimation={selectedAnimation}
                  setSelectedAnimation={setSelectedAnimation}
                  showBubble={showBubble}
                  setShowBubble={setShowBubble}
                />
              )}
              {configStep == 1 && (
                <ChatbotInformationEditor
                  nameEmptyWarning={nameEmptyWarning}
                  instructionsTooLongWarning={instructionsTooLongWarning}
                  instructionsEmptyWarning={instructionsEmptyWarning}
                  instructionsTokens={instructionsTokens}
                  chatbotName={chatbotName}
                  handleChatbotNameChanged={handleChatbotNameChanged}
                  chatbotDescription={chatbotDescription}
                  handleChatbotDescriptionChanged={handleChatbotDescriptionChanged}
                  chatbotInstructions={chatbotInstructions}
                  handleChatbotInstructionsChanged={handleChatbotInstructionsChanged}
                  chatbotLanguageModel={chatbotLanguageModel}
                  handleChatbotLanguageModelChanged={handleChatbotLanguageModelChanged}
                  chatbotLanguage={chatbotLanguage}
                  handleChatbotLanguageChanged={handleChatbotLanguageChanged}
                />
              )}
              {configStep == 2 && (
                <ChatbotDataEditor
                  dataSourceType={dataSourceType}
                  setDataSourceType={setDataSourceType}
                  noSourcesWarning={noSourcesWarning}
                  textSource={textSource}
                  handleTextSourceChanged={handleTextSourceChanged}
                  selectedUrlsCount={selectedUrlsCount}
                  handleClickGetUrls={handleClickGetUrls}
                  isDiscoveringUrls={isDiscoveringUrls}
                  targetUrl={targetUrl}
                  handleTargetUrlChanged={handleTargetUrlChanged}
                  sitemapUrl={sitemapUrl}
                  handleSitemapUrlChanged={handleSitemapUrlChanged}
                  manualUrl={manualUrl}
                  handleManualUrlChanged={handleManualUrlChanged}
                  handleClickAddManualUrl={handleClickAddManualUrl}
                  globalCheckboxChecked={globalCheckboxChecked}
                  handleGlobalCheckboxChanged={handleGlobalCheckboxChanged}
                  filteredUrls={filteredUrls}
                  setFilteredUrls={setFilteredUrls}
                />
              )}
              {configStep == 3 && (
                <ChatbotDomainsEditor
                  allowedDomainsCount={allowedDomainsCount}
                  noDomainsWarning={noDomainsWarning}
                  handleAllowedDomainChanged={handleAllowedDomainChanged}
                  handleClickAddAllowedDomain={handleClickAddAllowedDomain}
                  allowedDomains={allowedDomains}
                />
              )}
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment >
  );
}