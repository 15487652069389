import { useTranslation } from 'react-i18next';
import React from 'react';

function getNameOfConfigStep(configStep) {
  const { t } = useTranslation();
  switch (configStep) {
    case 0:
      return t('new_chatbot_step_1');
    case 1:
      return t('new_chatbot_step_2');
    case 2:
      return t('new_chatbot_step_3');
    case 3:
      return t('new_chatbot_step_4');
  }
}

const ChatbotPersonalizationStepsNavbar = ({ configStep, handleClickPrevious, handleClickNext, isButtonLoading, isNewChatbot }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-row items-center justify-between '>
      <div className='flex-1'>
        <h2 className='pl-0 pr-4 sm:pl-10 text-lg sm:text-xl md:text-3xl font-heading font-medium'>
          {getNameOfConfigStep(configStep)}
        </h2>
      </div>
      <div className='flex-1'>
        <div className='flex flex-wrap items-center justify-end -mr-4'>
          {configStep != 0 && (
            <div className=' my-1'>
              <button
                onClick={handleClickPrevious}
                className='px-3 py-3 mx-4 w-28 leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
              >
                {'< ' + t('new_chatbot_previous')}
              </button>
            </div>
          )}
          <div className='my-1' >
            <button
              onClick={handleClickNext}
              className={configStep != 3 ?
                'px-3 py-3 mx-4 w-28 leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl' :
                'px-3 py-3 mx-4 w-28 leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-green-600 focus:ring-opacity-50 rounded-xl'}
            >
              {isButtonLoading && (
                <svg className="text-white w-4 h-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                  <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                </svg>
              )}
              {configStep != 3 ? t('new_chatbot_next') + ' >' : isNewChatbot ? (isButtonLoading ? t('new_chatbot_creating') : t('new_chatbot_create')) : (isButtonLoading ? t('new_chatbot_updating') : t('new_chatbot_update'))}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotPersonalizationStepsNavbar;