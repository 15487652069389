import { useTranslation } from 'react-i18next';
import React from 'react';
import UrlTable from './UrlTable';

const ManualUrlSelector = ({ manualUrl, handleManualUrlChanged, handleClickAddManualUrl, globalCheckboxChecked, handleGlobalCheckboxChanged, filteredUrls, setFilteredUrls }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='border-t border-gray-100' />
      <h1 className='pl-0 sm:pl-10 my-3 text-lg font-bold'>
        {t('new_chatbot_manual_entry')}
      </h1>
      <div className='flex items-center justify-start'>
        <div className='pl-0 sm:pl-10 pr-4 md:pr-9 w-[40rem]'>
          <div>
            <input
              name='manual-url-input'
              className='w-full py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
              type='text'
              placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/blog/example-article'}
              value={manualUrl}
              onChange={handleManualUrlChanged}
            />
          </div>
        </div>
        <div className=''>
          <button
            onClick={handleClickAddManualUrl}
            className='block py-3 px-3 w-30 leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
          >
            {t('new_chatbot_add_url')}
          </button>
        </div>
      </div>
      <div className='mt-3 border-t border-gray-100' />
      <UrlTable
        globalCheckboxChecked={globalCheckboxChecked}
        handleGlobalCheckboxChanged={handleGlobalCheckboxChanged}
        filteredUrls={filteredUrls}
        setFilteredUrls={setFilteredUrls}
      />
    </div>
  );
};

export default ManualUrlSelector;