import { useTranslation } from 'react-i18next';
import React from 'react';

const ChatbotTextEditor = ({ textSource, handleTextSourceChanged }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className='mt-3 mb-4 text-lg font-bold'>
        {t('new_chatbot_enter_text')}
      </h1>
      <textarea
        className='w-full mb-3 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
        rows='15'
        cols='50'
        placeholder={t('new_chatbot_enter_text_description')}
        value={textSource}
        onChange={handleTextSourceChanged}
      />
    </div>
  );
};

export default ChatbotTextEditor;