import { useTranslation } from 'react-i18next';
import React from 'react';

function getSelectedUrlsMessage(urlCount) {
  const { t } = useTranslation();
  if (urlCount === 0) {
    return t('new_chatbot_no_urls');
  } else if (urlCount === 1) {
    return t('new_chatbot_1_url');
  } else {
    return `${urlCount} ` + t('new_chatbot_n_urls');
  }
}

const AutoUrlSelector = ({ selectedUrlsCount, handleClickGetUrls, isDiscoveringUrls, targetUrl, handleTargetUrlChanged, sitemapUrl, handleSitemapUrlChanged }) => {
  const { t } = useTranslation();
  return (
    <div className='pl-0 sm:pl-10 mb-4 w-auto md:w-[40rem]'>
      <div className='mb-3 flex'>
        <span className='text-indigo-500'>
          {getSelectedUrlsMessage(selectedUrlsCount)}
        </span>
      </div>
      <div className='border-t border-gray-100' />
      <div className='mb-1'>
        <div className='flex justify-between items-center'>
          <h1 className='my-3 text-lg font-bold'>
            {t('new_chatbot_url_discovery')}
          </h1>
          <div className='mt-2 w-full md:w-1/2 max-w-max md:ml-auto'>
            <button
              onClick={handleClickGetUrls}
              className='flex items-center justify-center py-3 px-3 ml-4 w-auto leading-4 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 rounded-xl'
            >
              {isDiscoveringUrls && (
                <svg className="text-white w-4 h-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                  <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                </svg>
              )}
              {t('new_chatbot_url_discovery_button')}
            </button>
          </div>
        </div>
        <div>
          <span>{t('new_chatbot_urls_starting')}</span>
          <input
            className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
            type='text'
            placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/blog'}
            value={targetUrl}
            onChange={handleTargetUrlChanged}
          />
        </div>
        <span>{t('new_chatbot_sitemap')}</span>
        <div>
          <input
            className='w-full mb-1 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
            type='text'
            placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/sitemap.xml'}
            value={sitemapUrl}
            onChange={handleSitemapUrlChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoUrlSelector;