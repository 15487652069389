import { useTranslation } from 'react-i18next';
import React from 'react';
import PageTitleSection from './PageTitleSection';

const NewChatbotTitleSection = ({ props }) => {
  const { t } = useTranslation();
  return (
    <PageTitleSection pageTitle={props.new ? t('new_chatbot_title') : t('new_chatbot_title_update')} buttonText={t('new_chatbot_cancel')} buttonLink='/dashboard' buttonColor='red' />
  );
};

export default NewChatbotTitleSection;