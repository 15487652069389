import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact(props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Navbar user={props.user} logout={props.logout} />
      <section className='py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
              <div className='max-w-lg lg:mt-20 mx-auto'>
                <h2 className='text-6xl md:text-7xl xl:text-8xl font-medium font-heading mb-4 lg:mb-6 leading-tight'>
                  {t('contact_title')}
                </h2>
                <p className='text-xl mb-4 lg:mb-6'>
                  {t('contact_description')}
                </p>
                <div className='py-4'>
                  <div className='flex items-center'>
                    <img
                      className='mr-6 h-14'
                      src='uinel-assets/images/contacts/icon3.svg'
                      alt='Icon representing an email address'
                    />
                    <a className='text-xl font-semibold' href='mailto:support@chattier.dev'>
                      support@chattier.dev
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* This ensures the Footer always stays at the bottom of the page */}
      <div className="flex-grow"></div> 
      <Footer />
    </div>
  );
}