import React, { useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserMenu = forwardRef(({ userMenuOpen, handleClickSignOut, top, left }, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const userMenu = document.getElementById('user-menu');
    if (userMenuOpen) {
      userMenu.classList.remove('hidden');
    } else {
      userMenu.classList.add('hidden');
    }
  }, [userMenuOpen]);

  return (
    <div
      ref={ref}
      id='user-menu'
      style={{
        top: `${top}px`,
        left: `${left}px`,
      }}
      className='hidden absolute z-[600] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
      role='menu'
      aria-orientation='vertical'
      aria-labelledby='user-menu-button'
      tabIndex='-1'>
      <div className='py-1' role='none'>
        <button
          id='user-menu-profile-button'
          onClick={() => {
            navigate('/profile');
          }}
          className='flex text-gray-700 px-4 py-2 w-full text-md text-left hover:bg-gray-100 hover:text-gray-900'
          role='menuitem'
          tabIndex='-1'>
          <span>{t('navbar_profile')}</span>
        </button>
        <button
          id='user-menu-dashboard-button'
          onClick={() => {
            navigate('/dashboard');
          }}
          className='flex text-gray-700 px-4 py-2 w-full text-md text-left hover:bg-gray-100 hover:text-gray-900'
          role='menuitem'
          tabIndex='-1'>
          <span>{t('navbar_chatbots')}</span>
        </button>
        <button
          id='user-menu-signout-button'
          onClick={handleClickSignOut}
          className='flex text-gray-700 px-4 py-2 w-full text-md text-left hover:bg-gray-100 hover:text-gray-900'
          role='menuitem'
          tabIndex='-1'>
          <span>{t('navbar_signout')}</span>
        </button>
      </div>
    </div>
  );
});

export default UserMenu;