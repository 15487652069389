import React, { useRef, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReducedAvailableLanguages } from './AvailableLanguages';

const LanguageMenu = forwardRef(({ languageMenuOpen, setLanguageMenuOpen }, ref) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const languageMenu = document.getElementById('language-menu');
    if (languageMenuOpen) {
      languageMenu.classList.remove('hidden');
    } else {
      languageMenu.classList.add('hidden');
    }
  }, [languageMenuOpen]);

  return (
    <div
      ref={ref}
      className='hidden absolute right-2 top-20 z-[600] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
      role='menu'
      id='language-menu'
      aria-orientation='vertical'
      aria-labelledby='menu-button'
      tabIndex='-1'>
      <div className='py-1' role='none'>
        {ReducedAvailableLanguages.map((language, index) => (
          <button
            key={index}
            onClick={() => {
              i18n.changeLanguage(language['value']);
              setLanguageMenuOpen(false);
            }}
            className='flex text-gray-700 px-4 py-2 w-full text-sm text-left hover:bg-gray-100 hover:text-gray-900'
            role='menuitem'
            tabIndex='-1'
            id={`menu-item-${index}`}>
            <img
              className='w-7 mr-2'
              src={language['flag']}
              alt='Language flag'
            />
            {t('languages_' + language['value'])}
            {i18n.language.split('-')[0] === language['value'] && (
              <svg
                className='ml-1 w-4 h-4'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M4.5 12.75l6 6 9-13.5'
                />
              </svg>
            )}
          </button>
        ))}
      </div>
    </div>
  );
});

export default LanguageMenu;