import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n/config';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    // <React.StrictMode> //TODO: Uncomment this line!!
        <BrowserRouter>
            <App />
        </BrowserRouter>
    // </React.StrictMode> //TODO: Uncomment this line!!
);
