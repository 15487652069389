import { useTranslation } from 'react-i18next';
import React from 'react';
import ChatbotAppearanceTabSelector from './ChatbotAppearanceTabSelector';
import BubbleChat from './BubbleChat';
import AvatarView from './AvatarView';
import AvatarSelector from './AvatarSelector';
import ColorSelector from './ColorSelector';
import ChatMinimizeSelector from './ChatMinimizeSelector';
import { getAssistantSizeInPixels, getAvatarUrl, getCameraParams, getChatSize } from './Utils';
import SizeSelector from './SizeSelector';
import VoiceAnimationSelector from './VoiceAnimationSelector';

const ChatbotAppearanceEditor = ({ chatbotType, setChatbotType, user, selectedChatSize, setSelectedChatSize, selectedColor, setSelectedColor, selectedAvatarSize, setSelectedAvatarSize, avatarIdentity, setAvatarIdentity, selectedVoiceSize, setSelectedVoiceSize, selectedAnimation, setSelectedAnimation, showBubble, setShowBubble }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ChatbotAppearanceTabSelector chatbotType={chatbotType} setChatbotType={setChatbotType} />
      <div className='flex flex-col sm:flex-row'>
        <div className={'flex-shrink-0 bg-transparent left-0 top-0 flex justify-center items-center ' + (chatbotType === 'avatar' ? 'w-auto sm:w-64' : 'w-auto')}>
          {/* <div style={{ transform: window.innerWidth < 475 ? 'scale(0.7)' : 'none', transformOrigin: 'top center' }}> */}
          <div className={'scale-[0.7] sm:transform-none -my-16 sm:my-0'}>
            {chatbotType === 'chat' && (
              <BubbleChat
                className='ml-9'
                user={user}
                chatbotId={'zI4jYhNFqimHuzZW2p6S'}
                size={getChatSize(selectedChatSize)}
                language={i18n.language}
                showBubble={showBubble}
                dashboardMode={true}
                parent='chattier.dev'
                color={selectedColor}
              />
            )}
          </div>
          {chatbotType === 'avatar' && (
            <AvatarView
              type='avatar'
              name='avatar-view'
              avatarUrl={getAvatarUrl(avatarIdentity)}
              showIFrame={false}
              user={user}
              chatbotId={'zI4jYhNFqimHuzZW2p6S'}
              sizeType={selectedAvatarSize}
              size={getAssistantSizeInPixels('avatar', selectedAvatarSize, avatarIdentity)}
              cameraParams={getCameraParams('avatar', avatarIdentity)}
              language={i18n.language}
              showBubble={null} // Not used for avatars
              dashboardMode={null} // Not used for avatars
              parent='chattier.dev'
              aid={avatarIdentity}
            />
          )}
          {chatbotType === 'voice' && (
            <AvatarView
              type='voice'
              name='voice-view'
              avatarUrl={getAvatarUrl(avatarIdentity)}
              showIFrame={false}
              user={user}
              chatbotId={'zI4jYhNFqimHuzZW2p6S'}
              sizeType={selectedVoiceSize}
              size={getAssistantSizeInPixels('voice', selectedVoiceSize)}
              cameraParams={getCameraParams('voice')}
              language={i18n.language}
              showBubble={null} // Not used for avatars
              dashboardMode={null} // Not used for avatars
              parent='chattier.dev'
              aid={avatarIdentity}
            />
          )}
        </div>
        {chatbotType === 'avatar' && (
          <div className=''>
            <h1 className='pl-0 sm:pl-9 mt-1 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_avatar')}
            </h1>
            <AvatarSelector selectedAvatar={avatarIdentity} setSelectedAvatar={setAvatarIdentity} />
            <h1 className='pl-0 sm:pl-9 mt-4 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_size')}
            </h1>
            <SizeSelector selectedSize={selectedAvatarSize} setSelectedSize={setSelectedAvatarSize} />
          </div>
        )}
        {chatbotType === 'chat' && (
          <div>
            <h1 className='pl-0 sm:pl-9 mt-1 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_color')}
            </h1>
            <ColorSelector selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
            <h1 className='pl-0 sm:pl-9 mt-4 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_size')}
            </h1>
            <SizeSelector selectedSize={selectedChatSize} setSelectedSize={setSelectedChatSize} />
            <h1 className='pl-0 sm:pl-9 mt-4 mb-2 text-lg font-bold'>
              {t('new_chatbot_minimization')}
            </h1>
            <ChatMinimizeSelector allowToMinimize={showBubble} setAllowToMinimize={setShowBubble} />
          </div>
        )}
        {chatbotType === 'voice' && (
          <div className=''>
            <h1 className='pl-0 sm:pl-9 mt-1 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_voice_animation')}
            </h1>
            <VoiceAnimationSelector selectedAnimation={selectedAnimation} setSelectedAnimation={setSelectedAnimation} />
            <h1 className='pl-0 sm:pl-9 mt-4 mb-2 text-lg font-bold'>
              {t('new_chatbot_select_size')}
            </h1>
            <SizeSelector selectedSize={selectedVoiceSize} setSelectedSize={setSelectedVoiceSize} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotAppearanceEditor;