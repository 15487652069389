import { useTranslation } from 'react-i18next';
import React from 'react';
import WarningIcon from './WarningIcon';
import { AvailableLanguageModels, AvailableLanguages } from './AvailableLanguages';

const ChatbotInformationEditor = ({ nameEmptyWarning, instructionsTooLongWarning, instructionsEmptyWarning, instructionsTokens, chatbotName, handleChatbotNameChanged, chatbotDescription, handleChatbotDescriptionChanged, chatbotInstructions, handleChatbotInstructionsChanged, chatbotLanguageModel, handleChatbotLanguageModelChanged, chatbotLanguage, handleChatbotLanguageChanged }) => {
  const { t } = useTranslation();
  return (
    <div className='mt-4 w-auto md:w-[40rem]'>
      {nameEmptyWarning && (
        <div className="flex mb-1">
          <WarningIcon />
          <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_name_empty_warning')}</p>
        </div>
      )}
      <div className='pl-0 sm:pl-10'>
        <span>{t('new_chatbot_name')}</span>
        <input
          className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
          type='text'
          placeholder={t('new_chatbot_name_eg')}
          value={chatbotName}
          onChange={handleChatbotNameChanged}
        />
      </div>
      <div className='pl-0 sm:pl-10'>
        <span>{t('new_chatbot_description')}</span>
        <textarea
          className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
          rows='3'
          cols='50'
          placeholder={t('new_chatbot_description_eg')}
          value={chatbotDescription}
          onChange={handleChatbotDescriptionChanged}
        />
      </div>
      {instructionsTooLongWarning && (
        <div className="flex mb-1">
          <WarningIcon />
          <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_instructions_too_long_warning', { tokens: instructionsTokens })}</p>
        </div>
      )}
      {instructionsEmptyWarning && (
        <div className="flex mb-1">
          <WarningIcon />
          <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_instructions_empty_warning')}</p>
        </div>
      )}
      <div className='pl-0 sm:pl-10'>
        <span>{t('new_chatbot_instructions')}</span>
        <textarea
          className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
          rows='6'
          cols='50'
          placeholder={t('new_chatbot_instructions_placeholder')}
          value={chatbotInstructions}
          onChange={handleChatbotInstructionsChanged}
        />
      </div>
      <div className="flex flex-col pl-0 sm:pl-10">
        <span>{t('new_chatbot_language_model')}</span>
        <select
          name="llm-model"
          id="llm-model"
          className="mb-4 w-60 h-10 rounded-xl border-2 border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          value={chatbotLanguageModel}
          onChange={handleChatbotLanguageModelChanged}>
          {AvailableLanguageModels.map((model) => {
            return (
              <option value={model['value']} key={model['value']}>{model['name']}</option>
            )
          })}
        </select>
      </div>
      <div className="flex flex-col pl-0 sm:pl-10">
        <span>{t('new_chatbot_language')}</span>
        <select
          name="language"
          id="language"
          className="w-60 h-10 rounded-xl border-2 border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          value={chatbotLanguage}
          onChange={handleChatbotLanguageChanged}>
          {AvailableLanguages.map((language) => {
            return (
              <option value={language['value']} key={language['value']}>{t('languages_' + language['value'].replace('-', '_'), { rgn: '(US)' })}</option>
            )
          })}
        </select>
      </div>
    </div>
  );
};

export default ChatbotInformationEditor;